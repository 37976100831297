import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { putApi } from "api/common/api";
import { API_URL } from "api/common/path";
import {
  useCustomerReservationEchartQuery,
  useEchartQuery
} from "api/useCustomerEcharts";
import SideElineIcon from "assets/icons/ic-side-eline.svg";
import SideSubmentalIcon from "assets/icons/ic-side-submental.svg";
import SideUpperlipIcon from "assets/icons/ic-side-upperlip.svg";
import { ROUTE } from "constants/paths";
import { NotifyTypeEnum } from "enums/notify";
import DialogEditSideFacePoint from "pages/FaceAnalysis/components/DialogEditSideFacePoint";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  drawEline,
  drawNoseAngle,
  drawSideDashLine,
  drawSubmandibularAngle
} from "services/draw-face-analysis.service";
// import { cropImageToBase64 } from "utils/face-analysis";
import { notify } from "utils/notify";

import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";
import NoImage from "./NoImage";

interface ISideFaceClinicProps {
  faceAnalysisResult: any;
  sideImage: string;
  header: string;
  afterComponent?: React.ReactNode;
  customer_id?: any;
  echart_id?: any;
  isReport: boolean;
}

const SideFaceClinic = (props: ISideFaceClinicProps) => {
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);
  const [isOpenHelp1, setIsOpenHelp1] = useState<boolean>(false);
  const [isOpenHelp2, setIsOpenHelp2] = useState<boolean>(false);

  const [isLoadingSideFace, setIsLoadingSideFace] = useState<boolean>(true);

  //--- Canvas for side
  const canvasEline = useRef<HTMLCanvasElement>(null);
  const canvasNoseAngle = useRef<HTMLCanvasElement>(null);
  const canvasSubmandibularAngle = useRef<HTMLCanvasElement>(null);
  const canvasSideDashLine = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataSideFaceLandmark, setDataSideFaceLandmark] = useState<any>();
  const [sideFaceAttributes, setSideFaceAttributes] = useState<any>();

  const [isEditSidePoint, setIsEditSidePoint] = useState(false);
  const [echartId, setEchartId] = useState<string | undefined>(undefined);
  // const [frontImgId, setFrontImgId] = useState<number>(0);
  const [scanResultId, setScanResultId] = useState<number>(0);
  const [oldFaceLandMarkResult, setOldFaceLandMarkResult] = useState<any>();

  // const sideFaceImg = new Image();
  // sideFaceImg.crossOrigin = "Anonymous";
  // sideFaceImg.src = props?.sideImage;

  const imgType =
    props.header === "After" ? "after_front_image" : "front_image";

  const handleOpenEditSidePoint = () => {
    setIsEditSidePoint(true);
  };

  const { id, res_id } = useParams();
  const { data: echart } = useCustomerReservationEchartQuery(
    id ? id : props?.customer_id || "",
    res_id ? res_id : props?.echart_id || ""
  );

  useEffect(() => {
    if (echart && echart[0]?.id) {
      setEchartId(echart[0]?.id);
    }
  }, [echart]);

  const { data: echartData } = useEchartQuery(echartId || props?.echart_id);

  useEffect(() => {
    if (echartData?.customer_echart?.[imgType]?.id) {
      setOldFaceLandMarkResult(
        echartData?.customer_echart?.[imgType]?.faceLandmarkResult
      );
      setScanResultId(echartData?.customer_echart?.[imgType]?.scan_result_id);
      // setFrontImgId(echartData?.customer_echart?.[imgType]?.id);
    }
  }, [echartData]);

  const navigate = useNavigate();

  const onLoadDataLandmark = async () =>
    // imgUrl: any,
    // imgWidth: number,
    // imgHeight: number
    {
      // const base64String = await cropImageToBase64(imgUrl, imgWidth, imgHeight);

      // if (base64String) {
      setDataSideFaceLandmark(
        echartData?.customer_echart?.[imgType]?.faceLandmarkResult
          ?.dataSideFaceLandmark
      );
      setSideFaceAttributes(
        echartData?.customer_echart?.[imgType]?.faceLandmarkResult
          ?.dataSideFaceAttributes
      );
      setIsLoadingSideFace(false);
      // }
    };

  //--- Get width and height of image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const handleImageLoad = (e: any) => {
    const { width, height } = e.target;

    if (props?.sideImage && width > 0 && height > 0) {
      setDimensionsRenderImg({ width, height });

      getDimensionsRealImg(props?.sideImage, (err: any, img: any) => {
        let imgRealWidth = Number(img?.width);
        let imageRealHeight = Number(img?.height);

        setDimensionsRealImg({ width: imgRealWidth, height: imageRealHeight });
      });
    }
  };

  useEffect(() => {
    if (echartData) {
      // onLoadDataLandmark(props?.sideImage, dimensions.width, dimensions.height);
      onLoadDataLandmark();
    }
  }, [echartData]);

  useEffect(() => {
    if (
      dataSideFaceLandmark &&
      sideFaceAttributes &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0
    ) {
      //今は写真取った画像のwidthは996になっています。
      // const scale = sideFaceImg.naturalWidth / dimensionsRenderImg.width;
      // const scaleimg = (point: any) => {
      //   return { x: point.x / scale, y: point.y / scale };
      // };

      // const scaledObject: { [key: string]: { x: number; y: number } } = {};
      // for (const key in dataSideFaceLandmark) {
      //   if (dataSideFaceLandmark.hasOwnProperty(key)) {
      //     scaledObject[key] = scaleimg(dataSideFaceLandmark[key]);
      //   }
      // }

      // Promise.all([
      //   //--- Draw eline
      //   drawEline(
      //     dataSideFaceLandmark,
      //     sideFaceAttributes,
      //     canvasEline,
      //     dimensionsRealImg.width,
      //     dimensionsRenderImg.width,
      //     dimensionsRenderImg.height
      //   ),
      //   //--- Draw nose angle
      //   drawNoseAngle(
      //     dataSideFaceLandmark,
      //     sideFaceAttributes,
      //     canvasNoseAngle,
      //     dimensionsRealImg.width,
      //     dimensionsRenderImg.width,
      //     dimensionsRenderImg.height
      //   ),
      //   //--- Draw submandibular angle
      //   drawSubmandibularAngle(
      //     dataSideFaceLandmark,
      //     sideFaceAttributes,
      //     canvasSubmandibularAngle,
      //     dimensionsRealImg.width,
      //     dimensionsRenderImg.width,
      //     dimensionsRenderImg.height
      //   )
      // ]);

      drawCanvasSideImg(
        dimensionsRealImg.width,
        dimensionsRenderImg.width,
        dimensionsRenderImg.height
      );
    }
  }, [
    dataSideFaceLandmark,
    sideFaceAttributes,
    dimensionsRealImg.width,
    dimensionsRenderImg.width,
    dimensionsRenderImg.height
  ]);

  const handleCloseEditSidePoint = () => {
    handleSubmitFaceAnalysis();
    setIsEditSidePoint(false);
  };

  const handleSubmitFaceAnalysis = async () => {
    try {
      oldFaceLandMarkResult.dataSideFaceLandmark = dataSideFaceLandmark;

      const body = {
        faceLandmarkResult: oldFaceLandMarkResult
      };
      oldFaceLandMarkResult.dataSideFaceLandmark = dataSideFaceLandmark;

      await submitFaceAnalysisMutation.mutateAsync(body);
      // navigate(
      //   `${ROUTE.CUSTOMER.INDEX}/${id}/customer_info/${res_id}/customer_questions?tab=1`
      // );
      // window.location.reload();
    } catch (error: any) {
      notify(error, NotifyTypeEnum.ERROR);
    }
  };

  const submitFaceAnalysisMutation = useMutation((bodyData: any) =>
    putApi(`${API_URL.FACE_ANALYSIS.CREATE}/${scanResultId}`, bodyData)
  );

  const handleDrawSideFaceAfterEditPoint = (dataSideFaceLandmark: any) => {
    setDataSideFaceLandmark({
      ...dataSideFaceLandmark
    });
    drawCanvasSideImg(
      dimensionsRenderImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const drawCanvasSideImg = (
    imgRealWidth: number,
    imgRenderWidth: number,
    imgRenderHeight: number
  ) => {
    Promise.all([
      //--- Draw eline
      drawEline(
        dataSideFaceLandmark,
        sideFaceAttributes,
        canvasEline,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw nose angle
      drawNoseAngle(
        dataSideFaceLandmark,
        sideFaceAttributes,
        canvasNoseAngle,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw submandibular angle
      drawSubmandibularAngle(
        dataSideFaceLandmark,
        sideFaceAttributes,
        canvasSubmandibularAngle,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw side dash line
      drawSideDashLine(
        dataSideFaceLandmark,
        sideFaceAttributes,
        canvasSideDashLine,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      )
    ]);
  };

  //--- Calculate scan level
  const calculateScanLevelNoseAngle = (ratioResult: any) => {
    if (ratioResult < 75) return 1;
    if (ratioResult >= 75 && ratioResult < 85) return 2;
    if (ratioResult >= 85 && ratioResult < 95) return 3;
    if (ratioResult >= 95 && ratioResult < 105) return 4;
    return 5;
  };

  const calculateScanLevelSubmandibular = (ratioResult: any) => {
    if (ratioResult < 75) return 1;
    if (ratioResult >= 75 && ratioResult < 85) return 2;
    if (ratioResult >= 85 && ratioResult < 95) return 3;
    if (ratioResult >= 95 && ratioResult < 105) return 4;
    return 5;
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <div
            className={`flex items-center justify-between ${!props.isReport ? "mb-2" : ""}`}
          >
            <span className="text-purple-500 text-sm">{props.header}</span>

            {!props.isReport && (
              <button
                className="bg-purple-500 text-white px-2 rounded"
                onClick={handleOpenEditSidePoint}
              >
                編集
              </button>
            )}
          </div>

          <div className="relative">
            <div className="w-fit">
              {props.sideImage && (
                <img
                  src={props?.sideImage}
                  alt="face-img"
                  className="max-w-full max-h-full"
                  onError={(e: any) => (e.target.src = NoImage)}
                  onLoad={handleImageLoad}
                />
              )}

              {/*--- Side face ---*/}
              <canvas
                ref={canvasEline}
                className={`absolute top-0 left-0 right-0`}
              />

              <canvas
                ref={canvasNoseAngle}
                className={`absolute top-0 left-0 right-0`}
              />

              <canvas
                ref={canvasSubmandibularAngle}
                className={`absolute top-0 left-0 right-0`}
              />

              <canvas
                ref={canvasSideDashLine}
                className={`absolute top-0 left-0 right-0`}
              />
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${
                isLoadingSideFace ? "" : "hidden"
              }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                isLoadingSideFace ? "" : "hidden"
              }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>

            {/* <div className="mt-12 w-full h-[1px] bg-[#262626]" /> */}

            {/* Nose Mouth Chin */}
            {/* {props.faceAnalysisResult?.checkedElineFeature && (
              <>
                <div className="flex items-center p-4">
                  <img src={SideElineIcon} alt="ratio icon" />
                  <span className="ml-2">鼻ー口ー顎</span>
                </div>
                <div className="mt-2 w-full h-[1px] bg-[#262626]" />
              </>
            )} */}

            {/* Nose */}
            {/* {props.faceAnalysisResult?.checkedNoseAngleFeature && (
              <>
                <div className="p-4">
                  <div className="flex items-start">
                    <img src={SideUpperlipIcon} alt="ratio icon" />

                    <div className="ml-2">
                      <span>鼻</span>
                      <div className="text-sm">
                        {props?.faceAnalysisResult?.noseAngle > 0
                          ? `${props?.faceAnalysisResult?.noseAngle}°`
                          : "0°"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 w-full h-[1px] bg-[#262626]" />
              </>
            )} */}

            {/* Submandibular */}
            {/* {props.faceAnalysisResult?.checkedSubmandibularAngleFeature && (
              <>
                <div className="p-4">
                  <div className="flex items-start">
                    <img src={SideSubmentalIcon} alt="ratio icon" />

                    <div className="ml-2">
                      <span>鼻</span>
                      <div className="text-sm">
                        {props?.faceAnalysisResult?.submandibularAngle > 0
                          ? `${props?.faceAnalysisResult?.submandibularAngle}°`
                          : "0°"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 w-full h-[1px] bg-[#262626]" />
              </>
            )} */}
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          {/* Nose Mouth Chin */}
          <ScanLevel
            showBefore={false}
            img={SideElineIcon}
            title="Eライン"
            onSetOpenHelp={setIsOpenHelp}
          />

          {/* Nose */}
          <ScanLevel
            img={SideUpperlipIcon}
            title="鼻下角度 (90°)"
            point={calculateScanLevelNoseAngle(
              props?.faceAnalysisResult?.noseAngle
            )}
            pointNames={["狭い", "平均", "広い"]}
            otherPoint={
              <div className="text-center text-sm">
                {props?.faceAnalysisResult?.noseAngle || 0}°{" "}
                {props?.faceAnalysisResult?.noseAngle > 0 && (
                  <span className="text-[#33B294]">
                    ({props?.faceAnalysisResult?.noseAngle - 90 > 0 ? "+" : "-"}
                    {Math.abs(props?.faceAnalysisResult?.noseAngle - 90)}°)
                  </span>
                )}
              </div>
            }
            onSetOpenHelp={setIsOpenHelp1}
          />

          {/* Submandibular */}
          <ScanLevel
            img={SideSubmentalIcon}
            title="あご下角度 (90°)"
            point={calculateScanLevelSubmandibular(
              props?.faceAnalysisResult?.submandibularAngle
            )}
            pointNames={["狭い", "平均", "広い"]}
            otherPoint={
              <div className="text-center text-sm">
                {props?.faceAnalysisResult?.submandibularAngle || 0}°{" "}
                {props?.faceAnalysisResult?.submandibularAngle > 0 && (
                  <span className="text-[#33B294]">
                    (
                    {props?.faceAnalysisResult?.submandibularAngle - 90 > 0
                      ? "+"
                      : "-"}
                    {Math.abs(
                      props?.faceAnalysisResult?.submandibularAngle - 90
                    )}
                    °)
                  </span>
                )}
              </div>
            }
            onSetOpenHelp={setIsOpenHelp2}
          />
        </div>
      )}

      {props.sideImage &&
        dataSideFaceLandmark &&
        sideFaceAttributes &&
        isEditSidePoint && (
          <DialogEditSideFacePoint
            openDialog={isEditSidePoint}
            onCloseDialog={() => handleCloseEditSidePoint()}
            sideImgUrl={props.sideImage}
            dataSideFaceLandmark={dataSideFaceLandmark}
            onSetDataSideFaceLandmark={(dataSideFaceLandmark) =>
              handleDrawSideFaceAfterEditPoint(dataSideFaceLandmark)
            }
            sideFaceAttributes={sideFaceAttributes}
          />
        )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="Eライン"
          content={
            <div>
              <p className="font-bold">【アドバイス】</p>
              一般的に美しいとされている横顔は、鼻先から顎先を結んだラインが一直線であるとバランスの取れた美しい顔とされてます。理想に近づくための施術がおすすめできます。
            </div>
          }
        />
      )}

      {isOpenHelp1 && (
        <HelpModal
          isOpen={isOpenHelp1}
          closeModal={() => setIsOpenHelp1(false)}
          title="鼻下角度"
          content={
            <div>
              <p className="font-bold">【基準】</p>
              90°が理想とされています
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              理想とどのくらい離れているのか確認し、理想に近づけるための施術をオススメできます。
            </div>
          }
        />
      )}

      {isOpenHelp2 && (
        <HelpModal
          isOpen={isOpenHelp2}
          closeModal={() => setIsOpenHelp2(false)}
          title="あご下角度"
          content={
            <div>
              <p className="font-bold">【基準】</p>
              90°が理想とされています
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              ①角度が広い場合
              <br />
              顔のたるみや二重顎といった問題がある可能性があります。
              <br />
              脂肪吸引やおとがい形成などの施術をオススメできます。
            </div>
          }
        />
      )}
    </>
  );
};

export default SideFaceClinic;
