export const INIT_ENV = process.env.REACT_APP_INIT_ENV || "DEV"; // DEV or PROD
export const FRONTEND_URL =
  process.env.REACT_APP_BACKEND_URL || "https://localhost:3001/";
export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "https://localhost:3000";

export const BASE_API_URL = {
  DEV: BACKEND_URL,
  PROD: "https://api.beauty-mira.com",
  STAG: BACKEND_URL
};

export const BASE_URL = {
  DEV: FRONTEND_URL,
  PROD: "https://api.beauty-mira.com",
  STAG: FRONTEND_URL
};

export const FACE_PLUS_PLUS_AUTH = {
  DEV: {
    API_KEY: "TkPcQ0mL8UpOZ55I-iR8vFu9-BkdlIQF",
    API_SECRET: "KSfAIfcqV7qqYldcMUyFUehHdvEWtRQe"
  },
  PROD: {
    API_KEY: "APCpOiBO_ekuHSoRgkiXyNLtS7LPI06s",
    API_SECRET: "WIvsBWWZqvb64LxcF6DF5BXV-PmQPKLN"
  },
  STAG: {
    API_KEY: "TkPcQ0mL8UpOZ55I-iR8vFu9-BkdlIQF",
    API_SECRET: "KSfAIfcqV7qqYldcMUyFUehHdvEWtRQe"
  }
};

export const STRIPE_PUBLISHABLE_KEY = {
  DEV: "pk_test_WESuBqg4mY3HGR62PTVwp2SJ",
  PROD: "pk_live_VFj2JNCWP1HUGDcRECqmy98D",
  STAG: "pk_test_WESuBqg4mY3HGR62PTVwp2SJ"
};
