export const ROUTE = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",

  FORGOT_PASSWORD: {
    INDEX: "/forgot-password",
    SEND_MAIL: "send-mail",
    MAIL_IS_SENT: "mail-is-sent",
    UPDATE_PASSWORD: "update",
    UPDATE_PASSWORD_SUCCESS: "update-success"
  },

  SALON: {
    INDEX: "/salon",
    CURRENT_PLAN: "#current-plan"
  },

  CUSTOMER: {
    INDEX: "/customer",
    ADD: "/customer/add",
    INFO: "/customer/:id",
    CUSTOMER_INFO: "/customer/:id/customer_info",
    CUSTOMER_QUESTIONS:
      "/customer/:id/customer_info/:res_id/customer_questions",
    PRE_PROPOSAL: "/customer/:id/customer_info/:res_id/pre_proposal",
    NOT_FOUND: "/404",
    CUSTOMER_BEFORE_AFTER_PHOTO:
      "/customer/:id/customer_info/:res_id/customer_echart/:customer_echart_id",
    RESERVATION_CREATE: "/customer/:id/reservation/",
    RESERVATION_UPDATE: "/customer/:id/reservation/:res_id/update"
  },
  MESSAGE: {
    INDEX: '/message',
    CREATE: '/message/create',
    TARGET: '/message/target',
    EDIT: '/message/edit/:id',
  },

  CUSTOMER_SURVEY: "/customer-survey",
  CUSTOMER_FORM: "/customer-form",
  CUSTOMER_BASIC_INFO: "/customer-basic-info",

  CUSTOMER_ECHART: {
    INDEX: "/customer-echart",
    INFO: "#info",
    TAKE_PHOTO: "take-photo",
    UPLOAD_PHOTO: "upload-photo",
    CONFIRM_PHOTO: "confirm-photo"
  },

  CUSTOMER_ENTER_INFORMATION: "/customer-enter-information",
  CUSTOMER_ENTER_SUCCESS: "/customer-enter-success",

  MENU_CATEGORY: {
    INDEX: "/menu-category",
    EDIT: "edit"
  },

  MENU: {
    INDEX: "menu-list",
    ADD: "menu-add",
    EDIT: "menu-edit"
  },

  FACE_ANALYSIS: "/face-analysis",
  PROPOSAL: {
    INDEX: "/proposal",
    SALON: "salon",
    CLINIC: "clinic"
  },
  FACE_COMPARISON: {
    INDEX: "/face-comparison"
  },
  MYPAGE: {
    INDEX: "/mypage",
    QUESTION: {
      INDEX: "/mypage/question",
      EDIT: "/mypage/question/:id/edit"
    },
    QUESTION_PRODUCT: {
      EDIT: "/mypage/question-product/:id/edit",
      ADD: "/mypage/question/:themeId/category/:categoryId/add"
    },
    TREATMENT_EXAMPLE: "/mypage/treatment-example",
    TREATMENT_EXAMPLE_ADD: "/mypage/treatment-example/add",
    TREATMENT_EXAMPLE_EDIT:
      "/mypage/treatment-example/:treatmentExampleId/edit",
    TAGS: "tags",
    TAG_PRODUCTS: "tag-products",
    ADMIN_INFORMATION: "admin-information",
    PLAN: "plan",
    BRAND_MANAGEMENT: "brand-management",
    ACCOUNT: "/mypage/account",
    ACCOUNT_DETAIL: "/mypage/account/:action",
    BRANCH: "/mypage/branch",
    BRANCH_EDIT: "/mypage/branch/:branchId/edit",
    BRANCH_ADD: "/mypage/branch/add",
    PROFILE: "/mypage/profile",
    USER: "/mypage/user",
    USER_EDIT: "/mypage/user/:userId/edit",
    USER_ADD: "/mypage/user/add",
    HELP_CENTER: "help-center",
    DIAGNOSIS_TYPE: "diagnosis-type",
    TYPE_MATCHING: "type-matching",
    FACE_SCAN: "face-scan",
    PAYMENT: "/mypage/payment",
    PAYMENT_DETAIL: "/mypage/payment-detail",
  },
  CUSTOMER_MYPAGE: "/:branchCode/customer-mypage",
  CUSTOMER_MYPROPOSAL:
    "/:branchCode/customer-mypage/:reservationId/my-proposal",
  CUSTOMER_QUESTION_STARTER: "/:branchCode/customer-question",
  CUSTOMER_QUESTION_SECOND:
    "/:branchCode/customer-question-basic/:questionThemeId",
  CUSTOMER_QUESTION: {
    PRODUCT: {
      SHOW: "/:branchCode/customer-question-theme/:questionThemeId/"
    },
    PRE_FACE_SCAN: "/:branchCode/face-scan/"
  },
  ANALYSIS: {
    INDEX: "/analysis"
  },
  NOT_FOUND: "/404"
};
