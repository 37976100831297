import Error from "../Error";

export interface IFormTextFieldProps {
  id?: string;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  register?: any;
  errors?: any;
  type?: string;
  labelClassName?: string;
  maxLength?: number;
  onChange?: any;
  defaultValue?: string;
  readOnly?: boolean;
  isDefault?: boolean;
  disabled?: boolean;
}

const RequiredLabel = () => <span className="text-[#FF0000]">*</span>;

export default function FormTextField(props: IFormTextFieldProps) {
  return (
    <div className={`flex flex-col ${props.inputContainerClassName}`}>
      {!!props.label && (
        <div className="flex justify-between">
          <span
            className={`block text-[13px] leading-[18px] ${props.labelClassName}`}
          >
            {props.label} {props.isRequired && <RequiredLabel />}
          </span>
          {props.isDefault && (
            <div className="text-sm bg-gray-300 px-4 py-1 rounded-xl">
              編集不可
            </div>
          )}
        </div>
      )}

      <div className={`w-full min-h-[2.75rem] mt-1`}>
        <input
          placeholder={props.placeholder ? props.placeholder : ""}
          className={`${props.inputClassName} ${
            !props.inputClassName ? "bg-white" : ""
          } focus:outline-neutral-300 border border-solid border-[#E3E3E3] w-full h-full px-4 py-[11px] rounded-[4px] placeholder:text-[17x] placeholder:leading-[22px] placeholder:text-[#BEBEBE]`}
          type={props.type || "text"}
          maxLength={props.maxLength}
          onChange={props.onChange}
          {...props.register}
          id={props.id || ""}
          autoComplete="off"
          aria-autocomplete="none"
          defaultValue={props.defaultValue}
          readOnly={props.readOnly}
          disabled={!!props.disabled}
        />
        {props.errors && (
          <div className="mt-1">
            <Error message={props.errors.message} />
          </div>
        )}
      </div>
    </div>
  );
}
