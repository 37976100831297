import {useQuery} from "@tanstack/react-query";
import {getApi} from "./common/api";
import {API_URL} from "./common/path";
import {notify} from "utils/notify";
import {NotifyTypeEnum} from "enums/notify";
import {ROUTE} from "constants/paths";
import {LOCAL_STORAGE_KEY} from "constants/common";

export const usePaymentGetQuery = () => {
  return useQuery(
    ["get_payment"],
    async () => {
      try {
        const {data} = await getApi(
          API_URL?.PAYMENT?.DETAIL,
          {}
        );
        if (data?.payment_plan?.stripe_subscription_id) localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "paid");
        else localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "unpaid");
        return data;
      } catch (error: any) {
        localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "unpaid");
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCompanyPaymentGetQuery = (id: string | number) => {
  return useQuery(
    ["get_company_payment"],
    async () => {
      try {
        const {data} = await getApi(
          API_URL?.PAYMENT?.COMPANY_DETAIL + `?company_id=${id}`,
          {}
        );
        if (data?.payment_plan) localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, 'paid');
        else localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "unpaid");
        return data;
      } catch (error: any) {
        localStorage.setItem(LOCAL_STORAGE_KEY.PAYMENT_STATUS, "unpaid");
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCompanyDetail = () => {
  return useQuery(
    ["get_company"],
    async () => {
      try {
        const {data} = await getApi(
          API_URL.COMPANIES.SALON_INFO,
          {}
        );
        return data?.company;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};