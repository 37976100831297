import { useQueryClient } from "@tanstack/react-query";
import { usePaymentGetQuery } from "api/usePayment";
import { PLAN_KEY, PRICE } from "constants/common";
import { ROUTE } from "constants/paths";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { formatCurrencyJa } from "utils/common";

import Container from "components/Container";

const PaymentDetail: React.FC = () => {
  const { data: paymentData, isLoading } = usePaymentGetQuery();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoading && !paymentData) {
      navigate(ROUTE.MYPAGE.PAYMENT);
    }
  }, [isLoading, paymentData]);

  return (
    <Container>
      <MenuHeader
        headerLabel={"お支払い情報"}
        leftLabel={"戻る"}
        leftLink={"/mypage"}
      />
      <div className="w-full flex flex-col gap-6 mt-4">
        <div className="text-[15PX] flex flex-col gap-4">
          <div>
            <span className="font-bold ">加入プラン</span>
            <span className="">（1アカウントを含む）</span>
          </div>
          <div className="ml-4">
            {paymentData?.payment_plan?.plan_name === PLAN_KEY?.standard?.value
              ? PLAN_KEY?.standard?.label
              : PLAN_KEY?.raito?.label}
          </div>
        </div>

        <div className="text-[15PX] flex flex-col gap-4">
          <div>
            <span className="font-bold ">追加アカウント数</span>
          </div>
          <div className="ml-4">
            {paymentData?.payment_plan?.staffs_amount}名
          </div>
        </div>

        <div className="w-full p-4 mt-1">
          <div className="flex flex-col gap-4 bg-[#EFEFEF] p-4 border border-[#B0B0B0]">
            <div className="flex flex-col gap-1">
              <label className="text-[15px]">プラン</label>
              <span className="text-bold text-[22px]">
                ¥
                {formatCurrencyJa(
                  paymentData?.payment_plan?.plan_name ===
                    PLAN_KEY?.standard?.value
                    ? PRICE?.standard
                    : PRICE?.raito
                )}
                /月
              </span>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[15px]">追加アカウント</label>
              <span className="text-bold text-[22px]">
                ¥{formatCurrencyJa(PRICE?.user)}/月
              </span>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-[15px]">合計</label>
              <div className="flex justify-start items-end gap-1">
                <span className="text-bold text-[22px]">
                  ¥
                  {formatCurrencyJa(
                    (paymentData?.payment_plan?.plan_name ===
                    PLAN_KEY?.standard?.value
                      ? PRICE?.standard
                      : PRICE?.raito) +
                      PRICE?.user * paymentData?.payment_plan?.staffs_amount
                  )}
                  /月{" "}
                </span>{" "}
                <span className="text-sm font-normal">（+消費税）</span>
              </div>
            </div>
          </div>
        </div>

        <button
          className="w-full border-none p-3 bg-[#525252] text-white mt-6 mb-2"
          onClick={() => {
            queryClient.setQueryData(["isEditPayment"], true);
            navigate(ROUTE.MYPAGE.PAYMENT);
          }}
        >
          変更する
        </button>

        <div className="text-[18px] mb-20 text-center">
          解約についてはヘルプをご参照ください。
          <div
            className="text-[18px] mt-3 text-center underline text-[#C991F4] cursor-pointer"
            onClick={() =>
              window.open(
                "https://gokindler.notion.site/AI-22ccc4eb504e4ce28a5122008df91d0a",
                "_blank"
              )
            }
          >
            ヘルプ
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentDetail;
