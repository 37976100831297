import SaveIcon from "@mui/icons-material/Save";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useRef, useState } from "react";
import React from "react";
import Draggable from "react-draggable";

import {
  drawEline,
  drawNoseAngle,
  drawSideDashLine,
  drawSubmandibularAngle
} from "../../../../services/draw-face-analysis.service";
import useDevice from "../../../../utils/use-device";

interface IDialogEditSideFacePointProps {
  openDialog: boolean;
  onCloseDialog: () => void;
  sideImgUrl: any;
  dataSideFaceLandmark: any;
  onSetDataSideFaceLandmark: (dataSideFaceLandmark: any) => void;
  sideFaceAttributes: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogEditSideFacePoint = (props: IDialogEditSideFacePointProps) => {
  const { isDesktop } = useDevice();

  //--- Canvas for side
  const canvasEline = useRef<HTMLCanvasElement>(null);
  const canvasNoseAngle = useRef<HTMLCanvasElement>(null);
  const canvasSubmandibularAngle = useRef<HTMLCanvasElement>(null);
  const canvasSideDashLine = useRef<HTMLCanvasElement>(null);

  //--- Drag point
  const contourChinSideDragRef = useRef(null);
  const noseSideDragRef = useRef(null);
  const contour8SideDragRef = useRef(null);
  const noseContour3SideDragRef = useRef(null);
  const contour5SideDragRef = useRef(null);

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });
  const [imgScale, setImgScale] = useState<any>();

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    const { width, height } = e.target;

    if (props.sideImgUrl && width > 0 && height > 0) {
      setDimensionsRenderImg({ width, height });

      getDimensionsRealImg(props.sideImgUrl, (err: any, img: any) => {
        let imgRealWidth = Number(img?.width);
        let imageRealHeight = Number(img?.height);

        setDimensionsRealImg({ width: imgRealWidth, height: imageRealHeight });

        setImgScale(imgRealWidth / width);

        if (
          props.sideImgUrl &&
          props.dataSideFaceLandmark &&
          props.sideFaceAttributes &&
          imgRealWidth > 0 &&
          width > 0 &&
          height > 0
        ) {
          drawCanvasSideImg(imgRealWidth, width, height);
        }
      });
    }
  };

  //--- Draw side image
  const drawCanvasSideImg = (
    imgRealWidth: number,
    imgRenderWidth: number,
    imgRenderHeight: number
  ) => {
    Promise.all([
      //--- Draw eline
      drawEline(
        props.dataSideFaceLandmark,
        props.sideFaceAttributes,
        canvasEline,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw nose angle
      drawNoseAngle(
        props.dataSideFaceLandmark,
        props.sideFaceAttributes,
        canvasNoseAngle,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw submandibular angle
      drawSubmandibularAngle(
        props.dataSideFaceLandmark,
        props.sideFaceAttributes,
        canvasSubmandibularAngle,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      ),
      //--- Draw side dash line
      drawSideDashLine(
        props.dataSideFaceLandmark,
        props.sideFaceAttributes,
        canvasSideDashLine,
        imgRealWidth,
        imgRenderWidth,
        imgRenderHeight
      )
    ]);
  };

  //--- Drag point
  const handleContourChinSideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      contour_chin: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleNoseLeftSideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      nose_left: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleNoseRightSideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      nose_right: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleContourLeft8SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      contour_left8: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleContourRight8SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      contour_right8: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleNoseContourLeft3SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      nose_contour_left3: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleNoseContourRight3SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      nose_contour_right3: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleContourLeft5SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      contour_left5: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  const handleContourRight5SideDrag = (e: any, position: any) => {
    props.onSetDataSideFaceLandmark({
      ...props.dataSideFaceLandmark,
      contour_right5: {
        x: position.x * imgScale,
        y: position.y * imgScale
      }
    });

    drawCanvasSideImg(
      dimensionsRealImg.width,
      dimensionsRenderImg.width,
      dimensionsRenderImg.height
    );
  };

  return (
    <Dialog
      open={props.openDialog}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      scroll={"paper"}
      aria-labelledby="side-scroll-dialog-title"
      aria-describedby="side-scroll-dialog-description"
      fullScreen={isDesktop ? false : true}
    >
      <DialogTitle>
        <div className="flex justify-end">
          <button
            className="px-6 py-2 text-white rounded-[4px] bg-[#33B294] flex items-center gap-x-2"
            onClick={() => props.onCloseDialog()}
          >
            <SaveIcon fontSize="small" />
            <span>保存</span>
          </button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="relative">
          {/*--- Contour chin side drag ---*/}
          <Draggable
            nodeRef={contourChinSideDragRef}
            position={{
              x: props.dataSideFaceLandmark?.contour_chin?.x / imgScale,
              y: props.dataSideFaceLandmark?.contour_chin?.y / imgScale
            }}
            onDrag={handleContourChinSideDrag}
          >
            <div
              className="box z-10 w-fit absolute cursor-move"
              ref={contourChinSideDragRef}
            >
              <div className="w-5 h-5 bg-white rounded-full absolute -top-[7px] -left-[7px]"></div>
            </div>
          </Draggable>

          {/*--- Nose side drag ---*/}
          <Draggable
            nodeRef={noseSideDragRef}
            position={{
              x:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.nose_left?.x / imgScale
                  : props.dataSideFaceLandmark?.nose_right?.x / imgScale,
              y:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.nose_left?.y / imgScale
                  : props.dataSideFaceLandmark?.nose_right?.y / imgScale
            }}
            onDrag={
              props.sideFaceAttributes?.headpose?.yaw_angle > 0
                ? handleNoseLeftSideDrag
                : handleNoseRightSideDrag
            }
          >
            <div
              className="box z-10 w-fit absolute cursor-move"
              ref={noseSideDragRef}
            >
              <div className="w-5 h-5 bg-white rounded-full absolute -top-[7px] -left-[7px]"></div>
            </div>
          </Draggable>

          {/*--- Contour 8 side drag ---*/}
          <Draggable
            nodeRef={contour8SideDragRef}
            position={{
              x:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.contour_right8?.x / imgScale
                  : props.dataSideFaceLandmark?.contour_left8?.x / imgScale,
              y:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.contour_right8?.y / imgScale
                  : props.dataSideFaceLandmark?.contour_left8?.y / imgScale
            }}
            onDrag={
              props.sideFaceAttributes?.headpose?.yaw_angle > 0
                ? handleContourRight8SideDrag
                : handleContourLeft8SideDrag
            }
          >
            <div
              className="box z-10 w-fit absolute cursor-move"
              ref={contour8SideDragRef}
            >
              <div className="w-5 h-5 bg-white rounded-full absolute -top-[7px] -left-[7px]"></div>
            </div>
          </Draggable>

          {/*--- Nose contour 3 side drag ---*/}
          <Draggable
            nodeRef={noseContour3SideDragRef}
            position={{
              x:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.nose_contour_right3?.x /
                    imgScale
                  : props.dataSideFaceLandmark?.nose_contour_left3?.x /
                    imgScale,
              y:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.nose_contour_right3?.y /
                    imgScale
                  : props.dataSideFaceLandmark?.nose_contour_left3?.y / imgScale
            }}
            onDrag={
              props.sideFaceAttributes?.headpose?.yaw_angle > 0
                ? handleNoseContourRight3SideDrag
                : handleNoseContourLeft3SideDrag
            }
          >
            <div
              className="box z-10 w-fit absolute cursor-move"
              ref={noseContour3SideDragRef}
            >
              <div className="w-5 h-5 bg-white rounded-full absolute -top-[7px] -left-[7px]"></div>
            </div>
          </Draggable>

          {/*--- Contour 5 side drag ---*/}
          <Draggable
            nodeRef={contour5SideDragRef}
            position={{
              x:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.contour_left5?.x / imgScale
                  : props.dataSideFaceLandmark?.contour_right5?.x / imgScale,
              y:
                props.sideFaceAttributes?.headpose?.yaw_angle > 0
                  ? props.dataSideFaceLandmark?.contour_left5?.y / imgScale
                  : props.dataSideFaceLandmark?.contour_right5?.y / imgScale
            }}
            onDrag={
              props.sideFaceAttributes?.headpose?.yaw_angle > 0
                ? handleContourLeft5SideDrag
                : handleContourRight5SideDrag
            }
          >
            <div
              className="box z-10 w-fit absolute cursor-move"
              ref={contour5SideDragRef}
            >
              <div className="w-5 h-5 bg-white rounded-full absolute -top-[7px] -left-[7px]"></div>
            </div>
          </Draggable>

          {props.sideImgUrl && (
            <img
              src={props.sideImgUrl}
              alt="front"
              className="w-full h-full max-h-full max-w-full"
              onLoad={handleImageLoad}
            />
          )}

          {/*--- Side face ---*/}
          <canvas
            ref={canvasEline}
            className={`absolute m-auto top-0 left-0 right-0`}
          />

          <canvas
            ref={canvasNoseAngle}
            className={`absolute m-auto top-0 left-0 right-0`}
          />

          <canvas
            ref={canvasSubmandibularAngle}
            className={`absolute m-auto top-0 left-0 right-0`}
          />

          <canvas
            ref={canvasSideDashLine}
            className={`absolute m-auto top-0 left-0 right-0`}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogEditSideFacePoint;
