import { useFaceProposalImageQuery } from "api/useFaceProposalType";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import FaceTypeClinic from "./FaceTypeClinic";

interface FaceScanTypeProps {
  faceAnalysisData: any;
  faceType: any;
}

const FaceScanType: React.FC<FaceScanTypeProps> = ({
  faceAnalysisData,
  faceType
}) => {
  const navigate = useNavigate();

  const { data: face_type_image } = useFaceProposalImageQuery(faceType);

  useEffect(() => {
    window.onpopstate = (e) => {
      navigate(0);
    };
  }, []);

  return (
    <FaceTypeClinic
      faceAnalysisResult={faceAnalysisData?.front_image?.faceAnalysisResult}
      frontImage={faceAnalysisData?.front_image?.url}
      faceTypeImage={face_type_image ? face_type_image.image : undefined}
    />
  );
};

export default FaceScanType;
