import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { ROUTE } from "../constants/paths";
import { isLoggedIn, isPaid } from "../utils/common";

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const isAuthenticated = isLoggedIn();
    const isPayment = isPaid();
    if (isAuthenticated) {
      // if (isPayment) {
      //   return <WrappedComponent {...props} />;
      // } else {
      //   return <Navigate to={ROUTE.MYPAGE.PAYMENT} />;
      // }
      return <WrappedComponent {...props} />;
    }
    return <Navigate to={ROUTE.LOGIN} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;
