import NoImage from "../NoImage";
import FaceContour from "./FaceContour";
import FaceRatio from "./FaceRatio";
import FaceTriangle from "./FaceTriangle";
import FacialLaterality from "./FacialLaterality";
import HorizontalBalance from "./HorizontalBalance";
import VerticalBalance from "./VerticalBalance";

interface IAllFaceScanProps {
  faceAnalysisData: any;
  frontImgUrl: any;
  afterFrontImgUrl: any;
}

const AllFaceScan: React.FC<IAllFaceScanProps> = ({
  faceAnalysisData,
  frontImgUrl,
  afterFrontImgUrl
}) => {
  return (
    <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
      <div className="text-2xl font-bold mb-2">顔全体のバランス</div>

      {faceAnalysisData && frontImgUrl && (
        <>
          {[
            { name: HorizontalBalance },
            { name: VerticalBalance },
            { name: FaceRatio },
            { name: FaceTriangle },
            { name: FaceContour },
            { name: FacialLaterality }
          ].map((config, index) => {
            const { name: AllFaceComponent } = config;

            return (
              <AllFaceComponent
                key={index}
                faceScanResult={faceAnalysisData?.front_image}
                frontImage={frontImgUrl}
                header={"Before"}
                imgFront={true}
                afterComponent={
                  faceAnalysisData && afterFrontImgUrl ? (
                    <AllFaceComponent
                      faceScanResult={faceAnalysisData?.after_front_image}
                      frontImage={afterFrontImgUrl}
                      header={"After"}
                      imgFront={false}
                    />
                  ) : (
                    <NoImage header={"After"} />
                  )
                }
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default AllFaceScan;
