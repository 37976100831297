import { CircularProgress } from "@mui/material";
// import { domToPng } from "modern-screenshot";
import { useRef, useState } from "react";

import NoseIcon1 from "../../../../assets/icons/ic-nose01.svg";
import NoseIcon2 from "../../../../assets/icons/ic-nose02.svg";
import NotAvailableImg from "../../../../assets/images/no-img-available.png";
import RatioSlider from "../../../../components/RatioSlider";
import {
  drawNoseContour,
  drawNoseRation
} from "../../../../services/draw-face-analysis.service";
import {
  calculateTwoRatio,
  getDistanceOfTwoPoint
} from "../../../../utils/face-analysis";
import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";

interface INoseClinicProps {
  faceScanResult: any;
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImage: string;
  header: string;
  element: string;
  setIsLoadingNose: any;
  isLoadingNose: boolean;
  afterComponent?: React.ReactNode;
}

const NoseClinic = (props: INoseClinicProps) => {
  // const isShowSection =
  //   props?.faceScanResult?.faceAnalysisResult?.checkedNoseFeature;

  const frontImg = props?.frontImage;

  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for nose
  const canvasNoseContour = useRef<HTMLCanvasElement>(null);
  const canvasNoseRatio = useRef<HTMLCanvasElement>(null);

  //--- Nose ratio
  const [noseRatio1, setNoseRatio1] = useState<number>(0);
  const [noseRatio2, setNoseRatio2] = useState<number>(0);

  const marksNoseRatio = [
    {
      value: 0
    },
    {
      value: noseRatio1 > 0 && noseRatio1
    },
    {
      value: 100
    }
  ];

  //--- Get data nose ratio
  const [scanLevel, setScanLevel] = useState<number>(0);

  const getDataNoseRatio = () => {
    const distinct1 = getDistanceOfTwoPoint(
      props.dataFaceLandmark?.nose_left,
      props.dataFaceLandmark.nose_right
    );

    const distinct2 = getDistanceOfTwoPoint(
      props.dataFaceLandmark?.contour_left2,
      props.dataFaceLandmark.contour_right2
    );

    const { ratio1, ratio2 } = calculateTwoRatio(distinct1, distinct2);

    setNoseRatio1(Number(ratio1));
    setNoseRatio2(Number(ratio2));

    const ratioResult = Number(Number(ratio1) / Number(ratio2));
    setScanLevel(calculateScanLevel(ratioResult));
  };

  //--- Calculate scan level
  const calculateScanLevel = (ratioResult: any) => {
    if (ratioResult < 0.16) return 1;
    if (ratioResult >= 0.16 && ratioResult < 0.2) return 2;
    if (ratioResult >= 0.2 && ratioResult < 0.24) return 3;
    if (ratioResult >= 0.24 && ratioResult < 0.28) return 4;
    return 5;
  };

  //--- Generate image
  // const [noseImgCrop, setNoseImgCrop] = useState<any>();

  // const generateImage = async (
  //   imgRealWidth: number,
  //   imgRenderWidth: number
  // ) => {
  //   props.setIsLoadingNose(true);

  //   const imageScale = imgRealWidth / imgRenderWidth;

  //   setTimeout(async () => {
  //     var noseNode = document.getElementById(props.element);

  //     if (noseNode === null) {
  //       return;
  //     }

  //     await domToPng(noseNode).then((dataUrl: any) => {
  //       if (dataUrl) {
  //         cropImage(
  //           dataUrl,
  //           0,
  //           Math.floor(props.faceRectangle?.top / imageScale) - 8,
  //           imgRenderWidth,
  //           Math.floor(props.faceRectangle?.height / imageScale)
  //         );
  //       }
  //     });

  //     props.setIsLoadingNose(false);
  //   }, 8000);
  // };

  // const cropImage = (
  //   imageUrl: any,
  //   cropX: any,
  //   cropY: any,
  //   cropWidth: any,
  //   cropHeight: any
  // ) => {
  //   const canvas = document.createElement("canvas");

  //   if (canvas && imageUrl) {
  //     const image = new Image();

  //     image.onload = () => {
  //       canvas.width = cropWidth;
  //       canvas.height = cropHeight;

  //       const ctxCropImage = canvas.getContext("2d");

  //       if (ctxCropImage)
  //         ctxCropImage.drawImage(
  //           image,
  //           cropX,
  //           cropY,
  //           cropWidth,
  //           cropHeight,
  //           0,
  //           0,
  //           cropWidth,
  //           cropHeight
  //         );

  //       const dataUrl = canvas.toDataURL();

  //       setNoseImgCrop(dataUrl);
  //     };

  //     image.setAttribute("crossorigin", "anonymous");
  //     image.src = imageUrl;
  //   }
  // };

  //--- Handle load image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    props.setIsLoadingNose(true);

    const { width, height } = e.target;

    getDimensionsRealImg(frontImg, (err: any, img: any) => {
      let imgRealWidth = Number(img?.width);

      if (
        imgRealWidth > 0 &&
        width > 0 &&
        height > 0 &&
        props?.dataFaceLandmark &&
        props?.dataFaceDenseLandmark &&
        frontImg
      ) {
        Promise.all([
          //--- Draw nose contour
          drawNoseContour(
            props?.dataFaceDenseLandmark,
            canvasNoseContour,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw nose ratio
          drawNoseRation(
            props?.dataFaceLandmark,
            canvasNoseRatio,
            imgRealWidth,
            width,
            height
          ),
          //--- Get data nose ratio
          getDataNoseRatio(),

          props.setIsLoadingNose(false)
          //--- Generate image
          // generateImage(imgRealWidth, width)
        ]);
      }
    });
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            {/* {noseImgCrop ? (
            <div className="h-auto w-full">
              <img
                src={noseImgCrop}
                alt="img crop"
                className="w-full h-[80px] object-contain"
              />
            </div>
          ) : (
            <> */}
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas nose ---*/}
                  <canvas
                    ref={canvasNoseContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasNoseRatio}
                    className={`absolute top-0 left-0 right-0 hidden`}
                  />
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${
                props.isLoadingNose ? "" : "hidden"
              }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                props.isLoadingNose ? "" : "hidden"
              }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
            {/* </>
          )} */}

            {/* <div className="mt-12 w-full h-[1px] bg-[#262626]" />
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={NoseIcon1} alt="ratio icon" />
                  <span className="ml-2">鼻輪郭</span>
                </div>
              </div>
            </div> */}

            {/* <div className="mt-2 w-full h-[1px] bg-[#262626]" />
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={NoseIcon2} alt="ratio icon" />
                  <span className="ml-2">小鼻の幅比</span>
                </div>
              </div>

              {noseRatio1 > 0 && noseRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksNoseRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(noseRatio1)}% / 2) - 1rem)`
                      }}
                    >
                      {props?.faceScanResult?.faceAnalysisResult?.noseRatio1 >
                        0 &&
                        props.faceScanResult?.faceAnalysisResult?.noseRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(noseRatio1)}% + (${Math.ceil(
                          noseRatio2
                        )}% / 2)) - 1rem)`
                      }}
                    >
                      {props?.faceScanResult?.faceAnalysisResult?.noseRatio2 >
                        0 &&
                        props?.faceScanResult?.faceAnalysisResult?.noseRatio2}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}
          </div>
        </div>
        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          <ScanLevel
            img={NoseIcon2}
            title="鼻の幅比 (20%)"
            point={scanLevel}
            pointNames={["細い", "平均", "太い"]}
            measure={
              noseRatio1 > 0 &&
              noseRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksNoseRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(noseRatio1)}% / 2) - 1rem)`
                      }}
                    >
                      {props?.faceScanResult?.faceAnalysisResult?.noseRatio1 >
                        0 &&
                        "鼻幅: " +
                          props.faceScanResult?.faceAnalysisResult?.noseRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(noseRatio1)}% + (${Math.ceil(
                          noseRatio2
                        )}% / 2)) - 1rem)`
                      }}
                    >
                      {props?.faceScanResult?.faceAnalysisResult?.noseRatio2 >
                        0 &&
                        "顔幅: " +
                          props?.faceScanResult?.faceAnalysisResult?.noseRatio2}
                    </span>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp}
          />

          <ScanLevel
            img={NoseIcon1}
            title="鼻輪郭"
            showBefore={false}
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="鼻"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              小鼻の幅比：20%（顔幅に対する鼻幅の比率）
              <br />
              <br />
              <p className="font-bold">【アドバイス】</p>
              小鼻の幅比が広い場合、小鼻縮小などの施術やノーズシャドウの入れ方などを提案がおすすめです。
            </div>
          }
        />
      )}
    </>
  );
};

export default NoseClinic;
