import { useQuery } from "@tanstack/react-query";
import { getApi } from "api/common/api";
import { API_URL } from "api/common/path";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { convertImgUrlToBase64 } from "utils/face-analysis";

import FaceScanBeforeAfter from "../FaceScanBeforeAfter";
import AllFaceScan from "../FaceScanBeforeAfter/AllFaceScan";
import OtherImages from "../FaceScanBeforeAfter/OtherImages";
import FaceScanType from "../FaceScanType";
import ReportModal from "./ReportModal";

interface FaceScanDataProps {
  customer_id: string;
  echart_id: string;
  reservationAllData?: any;
}

const FACE_SCAN_TABS = [
  "レポート",
  "フェイスタイプ",
  "顔全体",
  "顔下半分",
  "目",
  "眉",
  "鼻",
  "口",
  "横顔",
  "その他"
];

const FaceScanData: React.FC<FaceScanDataProps> = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabValue = Number(params.get("before_after"));

  //--- Prams
  const customerId = Number(props.customer_id);
  const customerEchartId = Number(props.echart_id);

  //--- State
  const [activeTab, setActiveTab] = useState<number>(tabValue || 1);
  const [isOpenModalReport, setOpenModalReport] = useState<boolean>(false);

  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();
  const [faceRectangle, setFaceRectangle] = useState<any>();
  const [faceType, setFaceType] = useState("");

  const [dataFaceLandmarkAfter, setDataFaceLandmarkAfter] = useState<any>();
  const [dataFaceDenseLandmarkAfter, setDataFaceDenseLandmarkAfter] =
    useState<any>();
  const [faceRectangleAfter, setFaceRectangleAfter] = useState<any>();

  const [frontImgUrl, setFrontImgUrl] = useState("");
  const [frontSideImgUrl, setFrontSideImgUrl] = useState("");

  const [afterFrontImgUrl, setAfterFrontImgUrl] = useState("");
  const [afterSideImgUrl, setAfterSideImgUrl] = useState("");

  const handleSpanClick = (id: number) => {
    setActiveTab(id);

    if (id === 0) {
      setOpenModalReport(true);
    }
  };

  //--- Get face analysis data
  const getFaceAnalysisData = async () => {
    try {
      const res = await getApi(
        `${API_URL.CUSTOMER_ECHART.INDEX}/${customerEchartId}`,
        {}
      );
      return res.data.customer_echart;
    } catch (error) {
      console.error({ error });
    }
  };

  const { data: faceAnalysisData } = useQuery({
    enabled: customerId > 0 && customerEchartId > 0,
    queryKey: ["get_report_face_data", customerId, customerEchartId],
    queryFn: getFaceAnalysisData,
    onSuccess: (data) => {
      const frontImgData = data?.front_image;
      const frontImg = frontImgData?.url;

      const afterFrontImgData = data?.after_front_image;
      const afterImg = afterFrontImgData?.url;

      if (frontImg) {
        convertImgUrlToBase64(frontImg, (imgBase64: string) => {
          setFrontImgUrl(imgBase64);
        });

        if (data?.side_image?.url) {
          convertImgUrlToBase64(data?.side_image?.url, (imgBase64: string) => {
            setFrontSideImgUrl(imgBase64);
          });
        }

        setDataFaceLandmark(
          frontImgData?.faceLandmarkResult?.dataFrontFaceLandmark
        );
        setDataFaceDenseLandmark(frontImgData?.faceDenseLandmarkResult);
        setFaceRectangle(
          frontImgData?.faceLandmarkResult?.dataFrontFaceRectangle
        );
        setFaceType(frontImgData?.faceAnalysisResult?.faceType);
      }

      if (afterImg) {
        convertImgUrlToBase64(afterImg, (imgBase64: string) => {
          setAfterFrontImgUrl(imgBase64);
        });

        if (data?.after_side_image?.url) {
          convertImgUrlToBase64(
            data?.after_side_image?.url,
            (imgBase64: string) => {
              setAfterSideImgUrl(imgBase64);
            }
          );
        }

        setDataFaceLandmarkAfter(
          afterFrontImgData?.faceLandmarkResult?.dataFrontFaceLandmark
        );
        setDataFaceDenseLandmarkAfter(
          afterFrontImgData?.faceDenseLandmarkResult
        );
        setFaceRectangleAfter(
          afterFrontImgData?.faceLandmarkResult?.dataFrontFaceRectangle
        );
      }
    }
  });

  return (
    <div className="p-2">
      <div className="mb-4">
        {FACE_SCAN_TABS.map((label, index) => (
          <span
            key={index}
            className={`text-sm rounded-full px-2 py-1 m-1 inline-block cursor-pointer ${
              activeTab === index
                ? "bg-[#6E6872] text-white"
                : "bg-[#E7E7E7] text-[#333333]"
            }`}
            onClick={() => handleSpanClick(index)}
          >
            {label}
          </span>
        ))}
      </div>

      {activeTab === 1 && (
        <div className="rounded-2xl bg-white p-4">
          <FaceScanType
            faceAnalysisData={faceAnalysisData}
            faceType={faceType}
          />
        </div>
      )}

      {activeTab === 2 && (
        <AllFaceScan
          faceAnalysisData={faceAnalysisData}
          frontImgUrl={frontImgUrl}
          afterFrontImgUrl={afterFrontImgUrl}
        />
      )}

      {activeTab !== 0 &&
        activeTab !== 1 &&
        activeTab !== 2 &&
        activeTab !== 9 && (
          <FaceScanBeforeAfter
            faceAnalysisData={faceAnalysisData}
            dataFaceLandmark={dataFaceLandmark}
            dataFaceDenseLandmark={dataFaceDenseLandmark}
            faceRectangle={faceRectangle}
            frontImgUrl={frontImgUrl}
            dataFaceLandmarkAfter={dataFaceLandmarkAfter}
            dataFaceDenseLandmarkAfter={dataFaceDenseLandmarkAfter}
            faceRectangleAfter={faceRectangleAfter}
            afterFrontImgUrl={afterFrontImgUrl}
            frontSideImgUrl={frontSideImgUrl}
            afterSideImgUrl={afterSideImgUrl}
            activeTab={activeTab}
            customer_id={customerId}
            echart_id={customerEchartId}
          />
        )}

      {activeTab === 9 && (
        <div className="rounded-2xl bg-white p-2 w-fit mx-auto">
          <div className="text-2xl font-bold mb-2">その他</div>
          <OtherImages faceAnalysisData={faceAnalysisData} />
        </div>
      )}

      {isOpenModalReport && (
        <ReportModal
          isOpen={isOpenModalReport}
          isReport={true}
          closeModal={() => setOpenModalReport(false)}
          title="全体レポート"
          customer_id={props.customer_id}
          echart_id={props.echart_id}
          faceType={faceType}
          faceAnalysisData={faceAnalysisData}
          dataFaceLandmark={dataFaceLandmark}
          dataFaceDenseLandmark={dataFaceDenseLandmark}
          faceRectangle={faceRectangle}
          frontImgUrl={frontImgUrl}
          dataFaceLandmarkAfter={dataFaceLandmarkAfter}
          dataFaceDenseLandmarkAfter={dataFaceDenseLandmarkAfter}
          faceRectangleAfter={faceRectangleAfter}
          afterFrontImgUrl={afterFrontImgUrl}
          frontSideImgUrl={frontSideImgUrl}
          afterSideImgUrl={afterSideImgUrl}
          reservationAllData={props.reservationAllData}
        />
      )}
    </div>
  );
};

export default FaceScanData;
