import IconRoundHelp from "assets/icons/ic-round-help.svg";
import React from "react";

interface IScanLevelProps {
  showBefore?: boolean;
  img: string;
  title: React.ReactNode;
  point?: number;
  pointNames?: string[];
  measure?: React.ReactNode;
  otherPoint?: React.ReactNode;
  onSetOpenHelp?: any;
  isShowHelp?: boolean;
}

const ScanLevel = ({
  showBefore = true,
  img,
  title,
  point,
  pointNames,
  measure,
  otherPoint,
  onSetOpenHelp,
  isShowHelp = true
}: IScanLevelProps) => {
  return (
    <div className="flex flex-col mb-3 relative gap-3">
      <div className="flex gap-1 items-start">
        <img src={img} alt="ratio icon" className="size-10" />
        <div className="space-y-2 flex-1">
          <div className="flex items-center gap-2 text-sm">
            <div className="whitespace-normal break-all">{title}</div>
            {showBefore && (
              <span className="text-white bg-[#545454] rounded-md p-1 text-xs">
                Before
              </span>
            )}
          </div>
          <div className="w-fit space-y-1 text-xs">
            {point && point > 0 && (
              <>
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((item: any, index: any) => (
                    <div
                      key={item}
                      className={`w-[30px] h-[10px] ${index === 0 ? "rounded-tl-sm rounded-bl-sm" : ""} ${index === 4 ? "rounded-tr-sm rounded-br-sm" : ""}`}
                      style={{
                        backgroundColor: point === item ? "#C181F3" : "#E5E6E6"
                      }}
                    />
                  ))}
                </div>

                {pointNames && (
                  <div className="flex justify-between whitespace-nowrap text-[#8F8F8F] text-[10px]">
                    <span>{pointNames[0]}</span>
                    <span className="col-start-3">{pointNames[1]}</span>
                    <span className="col-start-5">{pointNames[2]}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {isShowHelp && (
          <div className="cursor-pointer" onClick={() => onSetOpenHelp(true)}>
            <img src={IconRoundHelp} alt="help" />
          </div>
        )}
      </div>

      {measure}

      {otherPoint}

      <div className="w-full border-t-[1px] border-black mt-2" />
    </div>
  );
};

export default ScanLevel;
