import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  "width": "100%",
  "maxWidth": "100%",
  "color": "#ffffff",
  "height": 2,
  "padding": "15px 0",
  "& .MuiSlider-thumb": {
    "display": "none",
    "height": 24,
    "width": 24,
    "backgroundColor": "#fff",
    "boxShadow": iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      "boxShadow":
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow
      }
    }
  },
  "& .MuiSlider-valueLabel": {
    "fontSize": 12,
    "fontWeight": "normal",
    "top": -6,
    "backgroundColor": "unset",
    "color": theme.palette.text.primary,
    "&:before": {
      display: "none"
    },
    "& *": {
      background: "transparent",
      color: "transparent"
    }
  },
  "& .MuiSlider-track": {
    border: "none"
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#ffffff"
  },
  "& .MuiSlider-mark": {
    "backgroundColor": "#ffffff",
    "height": 8,
    "width": 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor"
    }
  }
}));

interface IRatioSliderProps {
  marksArr?: Array<any>;
}

const RatioSlider = (props: IRatioSliderProps) => {
  return (
    <IOSSlider
      aria-label="ios slider"
      defaultValue={100}
      marks={props.marksArr}
      valueLabelDisplay="on"
    />
  );
};

export default RatioSlider;
