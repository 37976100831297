import { CircularProgress } from "@mui/material";
import FaceIcon2 from "assets/icons/ic-face02.svg";
import NotAvailableImg from "assets/images/no-img-available.png";
import { useEffect, useRef, useState } from "react";
import { drawVerticalBalanceLine } from "services/draw-face-analysis.service";
import { calculateFiveRatio, getVerticalBalance } from "utils/face-analysis";

import RatioSlider from "components/RatioSlider";

import HelpModal from "../../FaceScanData/HelpModal";
import ScanLevel from "../../ScanLevel";

interface IFaceClinicProps {
  faceScanResult: any;
  frontImage: string;
  header: string;
  imgFront: boolean;
  afterComponent?: React.ReactNode;
}

const VerticalBalance = (props: IFaceClinicProps) => {
  const [isLoadingFace, setIsLoadingFace] = useState<boolean>(true);
  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for face
  const canvasVerticalBalance = useRef<HTMLCanvasElement>(null);

  //--- Get data landmark
  const [dataFaceLandmark, setDataFaceLandmark] = useState<any>();

  const getDataLandMark = async () => {
    const landmark =
      props?.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark;

    setDataFaceLandmark(landmark);

    setTimeout(() => {
      getDataDenseLandmark();
    }, 2000);
  };

  //--- Get data dense landmark
  const [dataFaceDenseLandmark, setDataFaceDenseLandmark] = useState<any>();

  const getDataDenseLandmark = async () => {
    const faceLandmark = props?.faceScanResult?.faceDenseLandmarkResult;
    setDataFaceDenseLandmark(faceLandmark);
    setIsLoadingFace(false);
  };

  //--- Load data face
  const onLoadDataFace = async () => {
    if (
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark &&
      props.faceScanResult?.faceLandmarkResult?.dataFrontFaceRectangle &&
      props.faceScanResult?.faceDenseLandmarkResult
    ) {
      setDataFaceLandmark(
        props.faceScanResult?.faceLandmarkResult?.dataFrontFaceLandmark
      );

      setDataFaceDenseLandmark(props.faceScanResult?.faceDenseLandmarkResult);

      setIsLoadingFace(false);
    } else {
      Promise.all([getDataLandMark()]);
    }
  };

  //--- Vertical ratio
  const [verticalRatio1, setVerticalRatio1] = useState<number>(0);
  const [verticalRatio2, setVerticalRatio2] = useState<number>(0);
  const [verticalRatio3, setVerticalRatio3] = useState<number>(0);
  const [verticalRatio4, setVerticalRatio4] = useState<number>(0);
  const [verticalRatio5, setVerticalRatio5] = useState<number>(0);

  const marksVerticalBalance = [
    {
      value: 0
    },
    {
      value: verticalRatio1 > 0 && verticalRatio1
    },
    {
      value: verticalRatio2 > 0 && verticalRatio1 + verticalRatio2
    },
    {
      value:
        verticalRatio3 > 0 && verticalRatio1 + verticalRatio2 + verticalRatio3
    },
    {
      value:
        verticalRatio4 > 0 &&
        verticalRatio1 + verticalRatio2 + verticalRatio3 + verticalRatio4
    },
    {
      value: 100
    }
  ];

  //--- Get data vertical ratio
  const [scanLevel, setScanLevel] = useState<number>(0);

  const getDataVerticalRatio = () => {
    const verticalRatio = getVerticalBalance(
      dataFaceLandmark,
      dataFaceDenseLandmark
    );
    const { ratio1, ratio2, ratio3, ratio4, ratio5 } = calculateFiveRatio(
      Number(verticalRatio.line1),
      Number(verticalRatio.line2),
      Number(verticalRatio.line3),
      Number(verticalRatio.line4),
      Number(verticalRatio.line5)
    );

    setVerticalRatio1(Number(ratio1));
    setVerticalRatio2(Number(ratio2));
    setVerticalRatio3(Number(ratio3));
    setVerticalRatio4(Number(ratio4));
    setVerticalRatio5(Number(ratio5));

    const maxNumber = findMaxNum([ratio1, ratio2, ratio3, ratio4, ratio5]);
    setScanLevel(calculateScanLevel(maxNumber - 20));
  };

  //--- Find max number
  const findMaxNum = (arr: any) => {
    return Math.max(...arr);
  };

  //--- Calculate scan level
  const calculateScanLevel = (ratioResult: any) => {
    if (ratioResult >= 12) return 1;
    if (ratioResult >= 9) return 2;
    if (ratioResult >= 6) return 3;
    if (ratioResult >= 3) return 4;
    return 5;
  };

  //--- Handle load image
  const [dimensionsRealImg, setDimensionsRealImg] = useState({
    width: 0,
    height: 0
  });
  const [dimensionsRenderImg, setDimensionsRenderImg] = useState({
    width: 0,
    height: 0
  });

  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    setTimeout(() => {
      const { width, height } = e.target;

      if (width > 0 && height > 0 && props.frontImage) {
        setDimensionsRenderImg({ width, height });

        getDimensionsRealImg(props.frontImage, (err: any, img: any) => {
          let imgRealWidth = Number(img?.width);
          let imageRealHeight = Number(img?.height);

          setDimensionsRealImg({
            width: imgRealWidth,
            height: imageRealHeight
          });

          if (props.frontImage && imgRealWidth > 0 && imageRealHeight > 0) {
            onLoadDataFace();
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (
      dataFaceLandmark &&
      dataFaceDenseLandmark &&
      dimensionsRealImg.width > 0 &&
      dimensionsRenderImg.width > 0 &&
      dimensionsRenderImg.height > 0 &&
      props.frontImage
    ) {
      Promise.all([
        //--- Get data vertical ratio
        getDataVerticalRatio(),

        //--- Draw vertical balance line
        drawVerticalBalanceLine(
          dataFaceLandmark,
          dataFaceDenseLandmark,
          canvasVerticalBalance,
          dimensionsRealImg.width,
          dimensionsRenderImg.width,
          dimensionsRenderImg.height
        )
      ]);
    }
  }, [dataFaceLandmark, dataFaceDenseLandmark]);

  return (
    <>
      <div
        className={`${props.imgFront ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.imgFront ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            <div className="w-fit">
              <img
                src={props?.frontImage}
                alt="balance-face"
                className="max-w-full max-h-full"
                onError={(e: any) => (e.target.src = NotAvailableImg)}
                onLoad={handleImageLoad}
              />

              {/*--- Canvas face ---*/}
              <canvas
                ref={canvasVerticalBalance}
                className={`absolute top-0 left-0 right-0`}
              ></canvas>
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${
                isLoadingFace ? "" : "hidden"
              }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                isLoadingFace ? "" : "hidden"
              }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          </div>
        </div>

        {props.afterComponent}
      </div>

      {props.imgFront && (
        <div className="mt-4">
          {/*--- Vertical balance ---*/}
          <ScanLevel
            img={FaceIcon2}
            title="顔横幅比 (1:1:1:1:1)"
            point={scanLevel}
            pointNames={["偏り強", "平均顔", "黄金比"]}
            measure={
              verticalRatio1 > 0 &&
              verticalRatio2 > 0 &&
              verticalRatio3 > 0 &&
              verticalRatio4 > 0 &&
              verticalRatio5 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksVerticalBalance} />
                  </div>
                  <div className="relative">
                    <div className="flex justify-around absolute -top-3 bottom-0 text-xs text-black w-full">
                      <span>{verticalRatio1}</span>
                      <span>{verticalRatio2}</span>
                      <span>{verticalRatio3}</span>
                      <span>{verticalRatio4}</span>
                      <span>{verticalRatio5}</span>
                    </div>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="顔横幅比"
          content={
            <div>
              <p className="font-bold">【黄金比】</p>
              1:1:1:1:1:1
              <p className="font-bold mt-4">【アドバイス】</p>
              ①黄金比により近づくような施術、メイクの提案
              <br />
              <br />
              ②目の幅に合わせた施術、髪型の提案
              <br />
              <br />
              例）
              <br />
              間隔が広い場合：アイラインは目頭側を太めに描き、目尻は細く。これにより視線が内側に集まり、間隔が近く見えます。ノーズシャドウを濃いめに入れることで、鼻筋の通った印象に。
              <br />
              間隔が狭い場合：アイラインは目頭を細めに、目尻側を太くします。これにより視線が外側に広がり、間隔が適度に見えます。また、目の下のアイシャドウを横長に入れることで、目元全体が広く見えます。
            </div>
          }
        />
      )}
    </>
  );
};

export default VerticalBalance;
