export interface CoordinatesPoint {
  x: number;
  y: number;
}
export interface IFace {
  [key: string]: CoordinatesPoint;
}

const getPointOfFace = (landmark: any) => {
  const result: any = [];
  Object.keys(landmark).forEach((key) => {
    Object.keys(landmark[key]).forEach((childKey) => {
      if (
        typeof landmark[key][childKey] === "function" ||
        typeof landmark[key][childKey] === "object"
      ) {
        result.push({
          x: landmark[key][childKey].x,
          y: landmark[key][childKey].y
        });
      }
    });
  });
  return result;
};

const drawLine = (
  ctx: any,
  info: { moveX: any; moveY: any; lineToX: any; lineToY: any },
  style: { color?: string; width?: any }
) => {
  const { moveX, moveY, lineToX, lineToY } = info;
  const { color, width } = style;

  ctx.beginPath();
  ctx.moveTo(moveX, moveY);
  ctx.lineTo(lineToX, lineToY);
  ctx.strokeStyle = color || "black";
  ctx.lineWidth = width || 1;
  ctx.stroke();
};

const drawDashLine = (
  ctx: any,
  info: { moveX: any; moveY: any; lineToX: any; lineToY: any },
  style: { width?: number; color?: string }
) => {
  const { moveX, moveY, lineToX, lineToY } = info;
  const { width, color } = style;

  ctx.beginPath();
  ctx.setLineDash([width || 1, width || 1]);
  ctx.moveTo(moveX, moveY);
  ctx.lineTo(lineToX, lineToY);
  ctx.strokeStyle = color || "black";
  ctx.stroke();
};

const getTriangleArea = (
  pointA: CoordinatesPoint,
  pointB: CoordinatesPoint,
  pointC: CoordinatesPoint
) => {
  return (
    0.5 *
    Math.abs(
      (pointB.x - pointA.x) * (pointC.y - pointA.y) -
        (pointC.x - pointA.x) * (pointB.y - pointA.y)
    )
  );
};

const getTrapezoidalArea = (
  pointA: CoordinatesPoint,
  pointB: CoordinatesPoint,
  pointC: CoordinatesPoint,
  pointD: CoordinatesPoint
) => {
  let S: number = 0;
  const S1 = getTriangleArea(pointA, pointB, pointC);
  const S2 = getTriangleArea(pointA, pointC, pointD);
  S = (S1 + S2) / 10000;
  return S.toFixed(1);
};

const getHorizontalBalance = (landmark: any, denseLandmark: any) => {
  const y1 = Math.floor(denseLandmark?.face?.face_hairline_74.y);
  const y2 = Math.floor(
    (landmark.left_eyebrow_upper_middle.y +
      landmark.right_eyebrow_upper_middle.y) /
      2
  );
  const y3 = Math.floor(landmark.nose_contour_lower_middle.y);
  const y4 = Math.floor(landmark.contour_chin.y);

  const line1 = y2 - y1;
  const line2 = y3 - y2;
  const line3 = y4 - y3;

  const _line1 = line1 / 3;
  const _line2 = line2 / 3;
  const _line3 = line3 / 3;

  const _getSmallerOne = () => {
    return _line1 > _line2 ? (line2 > _line3 ? _line3 : _line2) : _line1;
  };

  const targetVal = _getSmallerOne();

  const _result1 = (_line1 / targetVal).toFixed(1);
  const _result2 = (_line2 / targetVal).toFixed(1);
  const _result3 = (_line3 / targetVal).toFixed(1);

  const stringChecker = (string: any) => {
    if (string.indexOf(".0") !== -1) {
      return string.slice(0, 1);
    }
    return string;
  };

  return {
    line1: stringChecker(_result1),
    line2: stringChecker(_result2),
    line3: stringChecker(_result3)
  };
};

const getVerticalBalance = (
  dataFaceLandmark: any,
  dataFaceDenseLandmark: any
) => {
  const x1 = Math.floor(dataFaceDenseLandmark.face.face_contour_left_63.x);
  const x2 = Math.floor(dataFaceLandmark.left_eye_left_corner.x);
  const x3 = Math.floor(dataFaceLandmark.nose_left.x);
  const x4 = Math.floor(dataFaceLandmark.nose_right.x);
  const x5 = Math.floor(dataFaceLandmark.right_eye_right_corner.x);
  const x6 = Math.floor(dataFaceLandmark.contour_right1.x);

  const line1 = x2 - x1;
  const line2 = x3 - x2;
  const line3 = x4 - x3;
  const line4 = x5 - x4;
  const line5 = x6 - x5;

  const _line1 = line1 / 5;
  const _line2 = line2 / 5;
  const _line3 = line3 / 5;
  const _line4 = line4 / 5;
  const _line5 = line5 / 5;

  const lines = [_line1, _line2, _line3, _line4, _line5];

  const _ascendingOrder = (a: any, b: any) => {
    return a - b;
  };

  const targetLines = lines.sort(_ascendingOrder);
  const targetVal = targetLines[2];

  const _result1 = (_line1 / targetVal).toFixed(1);
  const _result2 = (_line2 / targetVal).toFixed(1);
  const _result3 = (_line3 / targetVal).toFixed(1);
  const _result4 = (_line4 / targetVal).toFixed(1);
  const _result5 = (_line5 / targetVal).toFixed(1);

  return {
    line1: _result1 === "1.0" ? 1 : _result1,
    line2: _result2 === "1.0" ? 1 : _result2,
    line3: _result3 === "1.0" ? 1 : _result3,
    line4: _result4 === "1.0" ? 1 : _result4,
    line5: _result5 === "1.0" ? 1 : _result5
  };
};

const getFacePoint = (face: IFace) => {
  const jawlinePoint: CoordinatesPoint[] = [];
  const sideFacePoint: CoordinatesPoint[] = [];
  const foreheadRimPoint: CoordinatesPoint[] = [];
  const CHIN_KEY_LEFT = "face_contour_left";
  const CHIN_KEY_RIGHT = "face_contour_right";
  const HAIRLINE_KEY = "face_hairline";
  const CHIN_INDEX = 29;
  const END_FOREHEAD_INDEX = 130;
  const START_FOREHEAD_INDEX = 10;

  const keys = Object.keys(face);

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];
    const indexKey = currentKey?.match(/\d+/)?.[0] as any;
    if (
      (currentKey.startsWith(CHIN_KEY_LEFT) ||
        currentKey.startsWith(CHIN_KEY_RIGHT)) &&
      Number(indexKey) <= CHIN_INDEX
    ) {
      jawlinePoint.push(face[currentKey]);
    }

    //--- Side face LEFT
    if (
      (currentKey.startsWith(HAIRLINE_KEY) && indexKey >= END_FOREHEAD_INDEX) ||
      (currentKey.startsWith(CHIN_KEY_LEFT) && indexKey > CHIN_INDEX)
    ) {
      sideFacePoint.push(face[currentKey]);
    }

    //--- Side face RIGHT
    if (
      (currentKey.startsWith(HAIRLINE_KEY) &&
        indexKey <= START_FOREHEAD_INDEX) ||
      (currentKey.startsWith(CHIN_KEY_RIGHT) && indexKey > CHIN_INDEX)
    ) {
      sideFacePoint.push(face[currentKey]);
    }

    if (
      currentKey.startsWith(HAIRLINE_KEY) &&
      (indexKey > START_FOREHEAD_INDEX || indexKey < END_FOREHEAD_INDEX)
    ) {
      foreheadRimPoint.push(face[currentKey]);
    }
  }

  return { jawlinePoint, sideFacePoint, foreheadRimPoint };
};

const getNosePoint = (nose: any) => {
  const nosePoint: CoordinatesPoint[] = [];
  const noseMidlinePoint: CoordinatesPoint[] = [];
  const NOSE_MIDLINE = "nose_midline";

  const keys = Object.keys(nose);

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];
    if (currentKey.startsWith(NOSE_MIDLINE))
      noseMidlinePoint.push(nose[currentKey]);
    else nosePoint.push(nose[currentKey]);
  }

  return { nosePoint, noseMidlinePoint };
};

const convertToCoordinatesPoint = (data: any) => {
  const result: CoordinatesPoint[] = [];
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i];
    if (typeof data[currentKey] === "object") result.push(data[currentKey]);
  }

  return result;
};

const getTrianglePoint = (landmark: any) => {
  const firstPint = {
    x: Math.floor(landmark.nose_contour_lower_middle.x),
    y: Math.floor(landmark.nose_contour_lower_middle.y)
  };

  const secondPint = {
    x: Math.floor(landmark.right_eye_center.x),
    y: Math.floor(landmark.right_eye_center.y)
  };

  const thirdPint = {
    x: Math.floor(landmark.left_eye_center.x),
    y: Math.floor(landmark.left_eye_center.y)
  };

  return {
    firstPint,
    secondPint,
    thirdPint
  };
};

const calculateTrianglePoint = (
  firstPint: any,
  secondPint: any,
  thirdPint: any
) => {
  const result =
    getLeftTriangleAngle(firstPint, thirdPint) +
    getRightTriangleAngle(firstPint, secondPint);

  return result.toFixed(1);
};

const getLeftTriangleAngle = (xy1: any, xy2: any) => {
  const targetX = xy2.x - xy1.x;
  const targetY = xy1.y - xy2.y;

  const targetXY = {
    x: targetX,
    y: targetY
  };

  const angle = Math.atan2(targetXY.y, targetXY.x) / (Math.PI / 180);
  return angle - 90;
};

const getRightTriangleAngle = (xy1: any, xy2: any) => {
  const targetX = xy2.x - xy1.x;
  const targetY = xy1.y - xy2.y;

  const targetXY = {
    x: targetX,
    y: targetY
  };

  const angle = Math.atan2(targetXY.y, targetXY.x) / (Math.PI / 180);
  return 90 - angle;
};

const calculateDistance = (pointA: any, pointB: any) => {
  return Math.sqrt(
    Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2)
  );
};

const calculateAngle = (pointA: any, pointB: any, pointC: any) => {
  const AB = calculateDistance(pointA, pointB);
  const AC = calculateDistance(pointA, pointC);
  const BC = calculateDistance(pointB, pointC);

  const cosTheta = (AB ** 2 + AC ** 2 - BC ** 2) / (2 * AB * AC);
  const angleRad = Math.acos(cosTheta);

  const angleDeg = angleRad * (180 / Math.PI);
  return angleDeg;
};

const getDistanceOfTwoPoint = (
  pointA: CoordinatesPoint,
  pointB: CoordinatesPoint
) => {
  const distance = Math.sqrt(
    Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2)
  );
  return distance;
};

const calculateTwoRatio = (line1: number, line2: number) => {
  const totalRatio = line1 + line2;

  const ratio1 = ((line1 / totalRatio) * 100).toFixed(1);
  const ratio2 = ((line2 / totalRatio) * 100).toFixed(1);

  return { ratio1, ratio2 };
};

const calculateThreeRatio = (line1: number, line2: number, line3: number) => {
  const totalRatio = line1 + line2 + line3;

  const ratio1 = ((line1 / totalRatio) * 100).toFixed(1);
  const ratio2 = ((line2 / totalRatio) * 100).toFixed(1);
  const ratio3 = ((line3 / totalRatio) * 100).toFixed(1);

  return { ratio1, ratio2, ratio3 };
};

const calculateFiveRatio = (
  line1: number,
  line2: number,
  line3: number,
  line4: number,
  line5: number
) => {
  const totalRatio = line1 + line2 + line3 + line4 + line5;

  const ratio1 = ((line1 / totalRatio) * 100).toFixed(1);
  const ratio2 = ((line2 / totalRatio) * 100).toFixed(1);
  const ratio3 = ((line3 / totalRatio) * 100).toFixed(1);
  const ratio4 = ((line4 / totalRatio) * 100).toFixed(1);
  const ratio5 = ((line5 / totalRatio) * 100).toFixed(1);

  return { ratio1, ratio2, ratio3, ratio4, ratio5 };
};

const drawFacePoint = (
  facePoints: any,
  imageScale: number,
  ctxFacePoint: any,
  style?: { color?: string }
) => {
  if (ctxFacePoint) {
    facePoints?.length > 0 &&
      facePoints.forEach((item: any) => {
        const x = Math.floor(item.x / imageScale);
        const y = Math.floor(item.y / imageScale);

        if (x > 0 && y > 0) {
          ctxFacePoint.fillStyle = style?.color || "#33B294";
          ctxFacePoint.fillRect(x, y, 1, 1);
        }
      });
  }
};

const drawCircle = (
  ctxCircle: any,

  coordinatesPoint: { x: any; y: any },
  imageScale: number,
  style: { color?: string; radius?: number }
) => {
  if (ctxCircle) {
    const { color, radius } = style;

    ctxCircle.beginPath();
    ctxCircle.arc(
      Math.floor(coordinatesPoint.x / imageScale),
      Math.floor(coordinatesPoint.y / imageScale),
      radius,
      0,
      1 * Math.PI,
      false
    );
    ctxCircle.fillStyle = color || "#ffffff";
    ctxCircle.fill();
  }
};

const getAcneAndPoresPoint = async (acne: any[], pores: any[]) => {
  const getAcnePointPromise = new Promise((resolve) => {
    const acnePoint =
      acne && acne.length > 0
        ? acne.reduce((result, cur) => {
            const points = cur.points;
            for (let i = 0; i < points.length; i++) {
              result.push(points[i]);
            }
            return result;
          }, [])
        : [];
    resolve(acnePoint);
  });

  const getPoresPointPromise = new Promise((resolve) => {
    const poresPoint =
      pores && pores.length > 0
        ? pores.reduce((result, cur) => {
            const points = cur.points;
            for (let i = 0; i < points.length; i++) {
              result.push(points[i]);
            }
            return result;
          }, [])
        : [];
    resolve(poresPoint);
  });

  const [acnePoint, poresPoint] = await Promise.all([
    getAcnePointPromise,
    getPoresPointPromise
  ]);

  return { acnePoint, poresPoint } as {
    acnePoint: CoordinatesPoint[];
    poresPoint: CoordinatesPoint[];
  };
};

const getTotalConditionSkinValues = (skinAnalyzeData: any) => {
  const {
    pores_jaw, // あごの毛穴
    pores_forehead, // ひたいの毛穴
    pores_right_cheek, // 右頬の毛穴
    pores_left_cheek, // 左頬の毛穴
    // nasolabial_fold, // 笑顔線
    eye_finelines, // 目元のシワ
    glabella_wrinkle, // 眉間のシワ
    forehead_wrinkle, // 額のシワ
    // eye_pouch, // 目元（涙袋）
    crows_feet, // 目じりのシワ
    blackhead, // にきび（頭部が黒くなったに）
    acne, // にきび
    // mole, // あざ
    // dark_circle, // クマ
    skin_spot // シミ
    // skin_type, // スキンタイプ
    // left_eyelids, // 左まぶた 目のタイプ
    // right_eyelids, // 右まぶた 目のタイプ
  } = skinAnalyzeData;

  const poresData =
    (_ensureRawValue(pores_jaw) +
      _ensureRawValue(pores_forehead) +
      _ensureRawValue(pores_right_cheek) +
      _ensureRawValue(pores_left_cheek)) /
    4.0;

  const wrinklesData =
    (_offsetWrinkleValue(eye_finelines, "eye_finelines") +
      _offsetWrinkleValue(glabella_wrinkle, "glabella_wrinkle") +
      _offsetWrinkleValue(forehead_wrinkle, "forehead_wrinkle") +
      _offsetWrinkleValue(crows_feet, "crows_feet")) /
    4.0;

  const acneData = (_ensureRawValue(blackhead) + _ensureRawValue(acne)) / 2.0;

  const skinSpotData = _ensureRawValue(skin_spot);

  // invert the value: e.g. high probability of having acne means a low score
  const poresValue = _invertValue(poresData);
  const wrinklesValue = _invertValue(wrinklesData);
  const acneValue = _invertValue(acneData);
  const skinSpotValue = _invertValue(skinSpotData);

  const values = [wrinklesValue, poresValue, skinSpotValue, acneValue];

  return values;
};

const getPoresConditionSkinValues = (skinAnalyzeData: any) => {
  const {
    pores_jaw, // あごの毛穴
    pores_forehead, // ひたいの毛穴
    pores_right_cheek, // 右頬の毛穴
    pores_left_cheek // 左頬の毛穴
  } = skinAnalyzeData;

  const jawData = _ensureRawValue(pores_jaw);
  const foreheadData = _ensureRawValue(pores_forehead);
  const rightCheekData = _ensureRawValue(pores_right_cheek);
  const leftCheekData = _ensureRawValue(pores_left_cheek);

  const jawValue = _invertValue(jawData);
  const foreheadValue = _invertValue(foreheadData);
  const rightCheekValue = _invertValue(rightCheekData);
  const leftCheekValue = _invertValue(leftCheekData);

  const values = [jawValue, foreheadValue, rightCheekValue, leftCheekValue];

  return values;
};

const getWrinkleConditionSkinValues = (skinAnalyzeData: any) => {
  const {
    // nasolabial_fold, // 笑顔線
    eye_finelines, // 目元のシワ
    glabella_wrinkle, // 眉間のシワ
    forehead_wrinkle, // 額のシワ
    crows_feet // 目じりのシワ
  } = skinAnalyzeData;

  const eyeFinelinesData = _offsetWrinkleValue(eye_finelines, "eye_finelines");
  const glabellaWrinkleData = _offsetWrinkleValue(
    glabella_wrinkle,
    "glabella_wrinkle"
  );
  const foreheadWrinkleData = _offsetWrinkleValue(
    forehead_wrinkle,
    "forehead_wrinkle"
  );
  const crowsFeetData = _offsetWrinkleValue(crows_feet, "crows_feet");

  // invert the value: e.g. high probability of having acne means a low score
  const eyeFinelinesValue = _invertValue(eyeFinelinesData);
  const glabellaWrinkleValue = _invertValue(glabellaWrinkleData);
  const foreheadWrinkleValue = _invertValue(foreheadWrinkleData);
  const crowsFeetValue = _invertValue(crowsFeetData);

  const values = [
    eyeFinelinesValue,
    glabellaWrinkleValue,
    foreheadWrinkleValue,
    crowsFeetValue
  ];

  return values;
};

const _ensureRawValue = (_obj: any) => {
  if (_obj.value === 0 && _obj.confidence > 0.5) {
    return 1 - _obj.confidence;
  }
  if (_obj.value === 1 && _obj.confidence < 0.5) {
    return 1 - _obj.confidence;
  }
  return _obj.confidence;
};

const miraWrinkleOffset: any = {
  eye_finelines: 0.463578,
  glabella_wrinkle: 0.235466,
  forehead_wrinkle: 0.143074,
  crows_feet: 0.0
};

const _offsetWrinkleValue = (_obj: any, _label: any) => {
  return Math.max(_ensureRawValue(_obj) - miraWrinkleOffset[_label], 0.0);
};

const _invertValue = (_val: any) => {
  // invert the rating and set to two decimal cases
  return Number((1 - _val).toFixed(2));
};

const calculateAngleThreePoint = (
  pointA: CoordinatesPoint,
  pointB: CoordinatesPoint,
  pointC: CoordinatesPoint
) => {
  const AB = Math.sqrt(
    Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2)
  );
  const BC = Math.sqrt(
    Math.pow(pointB.x - pointC.x, 2) + Math.pow(pointB.y - pointC.y, 2)
  );
  const AC = Math.sqrt(
    Math.pow(pointC.x - pointA.x, 2) + Math.pow(pointC.y - pointA.y, 2)
  );

  const angle = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
  return angle * (180 / Math.PI);
};

const cropImageToBase64 = (
  imageUrl: string,
  imgWidth: number,
  imgHeight: number
) => {
  return new Promise((resolve, reject) => {
    if (imageUrl && imgWidth && imgHeight) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = imgWidth;
        canvas.height = imgHeight;

        if (ctx) ctx.drawImage(img, 0, 0, imgWidth, imgHeight);

        const base64String = canvas.toDataURL("image/jpeg", 1.0);
        resolve(base64String);
      };

      img.onerror = (error) => {
        reject(error);
      };
    }
  });
};

const transformLandmark = (landmark: any, faceTop: any, faceLeft: any) => {
  const result: any = {};

  for (const key in landmark) {
    if (landmark.hasOwnProperty(key)) {
      const { x, y } = landmark[key];
      result[key] = {
        x: x - faceLeft,
        y: y - faceTop
      };
    }
  }

  return result;
};

const convertImgUrlToBase64 = (url: any, callback: any) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const getBase64Image = async (imgUrl: string) => {
  // First fetch the image
  const response = await fetch(imgUrl);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result); // will return base64 format
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const tinhPhuongTrinhDuongThang = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => {
  // tránh trường hợp đường thẳng song song trục x hoặc y
  if (y1 === y2) {
    y2 = y2 + 0.0000000001;
  }
  if (x1 === x2) {
    x2 = x2 + 0.0000000001;
  }
  if (x1 === x2) {
    // Đường thẳng song song với trục y, không có phương trình
    return { a: 0, b: 0 };
  } else {
    var a = (y2 - y1) / (x2 - x1);
    var b = y1 - a * x1;

    if (a === 0) {
      // Đường thẳng song song với trục x, có phương trình y = b
      return { a: 0, b: 0 };
    } else {
      // Đường thẳng có phương trình y = ax + b
      //return "Phương trình đường thẳng: y = " + a + "x + " + b;
      return { a, b };
    }
  }
};

export const tinhVectorChiPhuong = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => ({
  x: x2 - x1,
  y: y2 - y1
});

export const tinhGiaoDiem = (
  a1: number,
  b1: number,
  a2: number,
  b2: number
) => ({
  x: (b2 - b1) / (a1 - a2),
  y: (a1 * b2 - a2 * b1) / (a1 - a2)
});

// y = ax + b // Đây là đường thẳng AB
const calculateHeadTiltedPoint = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number
) => {
  if (!x1 || !y1 || !x2 || !y2 || !x3 || !y3) return { x: 0, y: 0 };

  // tránh trường hợp đường thẳng song song trục x hoặc y
  if (y1 === y2) {
    y2 = y2 + 0.0000000001;
  }
  if (x1 === x2) {
    x2 = x2 + 0.0000000001;
  }

  const { a, b } = tinhPhuongTrinhDuongThang(x1, y1, x2, y2);
  const vectorChiPhuongAB = tinhVectorChiPhuong(x1, y1, x2, y2);
  // (x,y)
  //
  const ABX = vectorChiPhuongAB.x;
  const ABY = vectorChiPhuongAB.y;

  //vectorChiPhuongAB.x * (x3 - X) + vectorChiPhuongAB.y * (y3 - a * X - b) = 0
  const Hx = (ABX * x3 + ABY * y3 - ABY * b) / (ABX + ABY * a);

  const Hy = a * Hx + b;

  const vectorHB = tinhVectorChiPhuong(Hx, Hy, x2, y2);
  const Dx = x3 + vectorHB.x;
  const Dy = y3 + vectorHB.y;

  return { x: Dx, y: Dy };
};

export const middlePoint = (
  point1: { x: number; y: number },
  point2: { x: number; y: number }
) => {
  return {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2
  };
};

const calculateTrapezoidArea = (landmarks: any, imageScale: any) => {
  const topWidth = Math.abs(
    landmarks.left_eyebrow_left_corner.x / imageScale -
      landmarks.right_eyebrow_right_corner.x / imageScale
  );

  const bottomWidth = Math.abs(
    landmarks.contour_left6.x / imageScale -
      landmarks.contour_right6.x / imageScale
  );

  const height = Math.abs(
    landmarks.nose_tip.y / imageScale - landmarks.contour_chin.y / imageScale
  );

  return ((topWidth + bottomWidth) / 2) * height;
};

const calculateFaceArea = (landmarks: any, imageScale: any) => {
  const left = landmarks.contour_left1.x / imageScale;
  const right = landmarks.contour_right1.x / imageScale;
  const top = landmarks.contour_chin.y / imageScale;
  const bottom = landmarks.contour_left6.y / imageScale;

  const width = Math.abs(right - left);
  const height = Math.abs(bottom - top);

  return width * height;
};

export {
  getPointOfFace,
  drawLine,
  drawDashLine,
  getVerticalBalance,
  getHorizontalBalance,
  getTrapezoidalArea,
  getFacePoint,
  getLeftTriangleAngle,
  getRightTriangleAngle,
  getTrianglePoint,
  calculateTrianglePoint,
  getDistanceOfTwoPoint,
  calculateTwoRatio,
  calculateThreeRatio,
  calculateFiveRatio,
  drawFacePoint,
  getNosePoint,
  convertToCoordinatesPoint,
  drawCircle,
  getAcneAndPoresPoint,
  getTotalConditionSkinValues,
  getPoresConditionSkinValues,
  getWrinkleConditionSkinValues,
  calculateAngleThreePoint,
  cropImageToBase64,
  transformLandmark,
  convertImgUrlToBase64,
  getBase64Image,
  calculateHeadTiltedPoint,
  calculateTrapezoidArea,
  calculateFaceArea,
  calculateAngle
};
