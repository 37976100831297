import {
  useCustomerReservationEchartQuery,
  useEchartQuery
} from "api/useCustomerEcharts";
import { useReservationQuery } from "api/useReservation";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import FaceTypeProposal from "./FaceTypeProposal";

interface Images {
  id: number;
  url: string;
}

const ShareProposal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [customerId, setCustomerId] = useState<number | undefined>(undefined);
  const [beforeImageExists, setBeforeImageExists] = useState<boolean>(false);
  const [afterImageExists, setAfterImageExists] = useState<boolean>(false);
  const [echartId, setEchartId] = useState<string | undefined>(undefined);
  const [frontImg, setFrontImg] = useState<any>(undefined);
  const [afterFrontImg, setAfterFrontImg] = useState<any>(undefined);
  const [imageListBefore, setImageListBefore] = useState<Images[]>([]);
  const [imageListAfter, setImageListAfter] = useState<Images[]>([]);

  const handleSpanClick = (id: number) => {
    setActiveTab(id);
  };
  const { reservationId } = useParams<{ reservationId: string }>();

  const { data: reservation } = useReservationQuery(reservationId || "0");
  useEffect(() => {
    if (reservation) {
      setCustomerId(reservation.reservation.customer_id);
    }
  }, [reservation]);

  const { data: echart } = useCustomerReservationEchartQuery(
    customerId?.toString() || "",
    reservationId || ""
  );

  useEffect(() => {
    if (echart) {
      setBeforeImageExists(echart[0].image_existed);
      setAfterImageExists(echart[0].after_image_existed);
      setEchartId(echart[0].id);
    }
  }, [echart]);

  const { data: echartData } = useEchartQuery(echartId);
  useEffect(() => {
    setImageListBefore([]);
    setImageListAfter([]);
    if (beforeImageExists) {
      setFrontImg(echartData?.customer_echart?.front_image);
      if (
        echartData?.customer_echart?.front_image?.id &&
        echartData?.customer_echart?.front_image?.url
      ) {
        setImageListBefore((prevList) => [
          ...prevList,
          {
            id: echartData?.customer_echart?.front_image?.id,
            url: echartData?.customer_echart?.front_image?.url
          }
        ]);
      }
      if (
        echartData?.customer_echart?.side_image?.id &&
        echartData?.customer_echart?.side_image?.url
      ) {
        setImageListBefore((prevList) => [
          ...prevList,
          {
            id: echartData?.customer_echart?.side_image?.id,
            url: echartData?.customer_echart?.side_image?.url
          }
        ]);
      }
    }

    if (afterImageExists) {
      setAfterFrontImg(echartData?.customer_echart?.after_front_image);
      if (
        echartData?.customer_echart?.after_front_image?.id &&
        echartData?.customer_echart?.after_front_image?.url
      ) {
        setImageListAfter((prevList) => [
          ...prevList,
          {
            id: echartData?.customer_echart?.after_front_image?.id,
            url: echartData?.customer_echart?.after_front_image?.url
          }
        ]);
      }
      if (
        echartData?.customer_echart?.after_side_image?.id &&
        echartData?.customer_echart?.after_side_image?.url
      ) {
        setImageListAfter((prevList) => [
          ...prevList,
          {
            id: echartData?.customer_echart?.after_side_image?.id,
            url: echartData?.customer_echart?.after_side_image?.url
          }
        ]);
      }
    }
  }, [echartData]);

  useEffect(() => {
    if (
      echartData?.customer_echart?.other_images &&
      imageListBefore.length >= 2
    ) {
      const echartOtherImages = echartData?.customer_echart?.other_images;
      if (echartOtherImages && echartOtherImages.length > 0) {
        const lastImageId =
          imageListBefore.length > 0
            ? imageListBefore[imageListBefore.length - 1].id
            : 0;
        const newImages = echartOtherImages.filter(
          (image: any) => image.id > lastImageId
        );

        if (newImages.length > 0) {
          setImageListBefore((prevList) => [...prevList, ...newImages]);
        }
      }
    }
  }, [frontImg]);

  return (
    <div className="py-4">
      <>
        <span
          className={`text-sm rounded-full px-3 py-2 m-1 inline-block cursor-pointer ${activeTab === 0 ? "bg-gray-700 text-white" : "bg-gray-200"
            }`}
          onClick={() => handleSpanClick(0)}
        >
          ビフォー
        </span>
        <span
          className={`text-sm rounded-full px-3 py-2 m-1 inline-block cursor-pointer ${activeTab === 1 ? "bg-gray-700 text-white" : "bg-gray-200"
            }`}
          onClick={() => handleSpanClick(1)}
        >
          アフター
        </span>
        {/* <span
          className={`text-sm rounded-full px-3 py-2 m-1 inline-block cursor-pointer ${
            activeTab === 2 ? "bg-gray-700 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleSpanClick(2)}
        >
          その他
        </span> */}
      </>
      <div>
        {activeTab === 0 && (
          <div className="w-full mt-4">
            {frontImg && frontImg?.faceAnalysisResult && frontImg?.url ? (
              <div className="w-full">
                <FaceTypeProposal
                  faceAnalysisResult={frontImg?.faceAnalysisResult}
                  frontImage={frontImg?.url}
                  imgList={imageListBefore}
                />
              </div>
            ) : (
              <div>データーなし</div>
            )}
          </div>
        )}
        {activeTab === 1 && (
          <div className="w-full mt-4">
            {afterFrontImg &&
              afterFrontImg?.faceAnalysisResult &&
              afterFrontImg?.url ? (
              <div className="w-full">
                <FaceTypeProposal
                  faceAnalysisResult={afterFrontImg?.faceAnalysisResult}
                  frontImage={afterFrontImg?.url}
                  imgList={imageListAfter}
                />
              </div>
            ) : (
              <div>データーなし</div>
            )}
          </div>
        )}
        {/* {activeTab === 2 && (
          <div className="w-1/2 mt-4">
            <p>その他(仮画像)</p>
            <img
              src="https://api.beauty-mira.com/face_proposal_types/front/1_f_honey_1.png"
              alt="face_type_image"
              className="mx-auto"
            />
            <div className="w-full mt-10">
              <h2 className="text-lg">コメント</h2>
            </div>
          </div>
        )} */}
      </div>
      <div className="w-full mt-10">
        <h2 className="text-xl font-bold my-5">コメント</h2>
        {reservation && (
          <div className="bg-white w-full border border-solid border-gray-300 rounded-md p-5">
            <p className="">{reservation.reservation.share_comment}</p>
          </div>
        )}
        <h2 className="text-xl font-bold mt-5">共有URL</h2>
        {reservation && (
          <Link
            to={reservation.reservation.share_url}
            target="_blank"
            className="text-md underline mt-3"
          >
            {reservation.reservation.share_url}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ShareProposal;
