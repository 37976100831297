import DandyTypeImg from "assets/images/men-face-type/dandy.png";
import ElegantTypeImg from "assets/images/men-face-type/elegant.png";
import SharpTypeImg from "assets/images/men-face-type/sharp.png";
import SoftTypeImg from "assets/images/men-face-type/soft.png";
import ClearTypeImg from "assets/images/woman-face-type/clear.png";
import HoneyTypeImg from "assets/images/woman-face-type/honey.png";
import SexyTypeImg from "assets/images/woman-face-type/sexy.png";
import StylishTypeImg from "assets/images/woman-face-type/stylish.png";

interface IFaceTypeClinicProps {
  faceAnalysisResult: any;
  frontImage: string;
  faceTypeImage: string | undefined;
}

const FaceTypeClinic = (props: IFaceTypeClinicProps) => {
  //--- Render face type
  const renderFaceType = (faceType: any, faceTypeDesc: any) => {
    switch (faceType) {
      case "Dandy":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={DandyTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Elegant":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={ElegantTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Sharp":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={SharpTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Soft":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={SoftTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Clear":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={ClearTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Honey":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={HoneyTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Sexy":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={SexyTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
      case "Stylish":
        return (
          <>
            <div style={{ filter: "invert(100%)" }}>
              <img
                src={StylishTypeImg}
                alt={faceTypeDesc}
                className="mx-auto mb-4"
              />
            </div>
            <p className="text-black whitespace-normal break-normal">
              {faceTypeDesc}
            </p>
          </>
        );
    }
  };

  return (
    <>
      {props?.faceAnalysisResult &&
      props?.faceAnalysisResult?.checkedMouthFeature ? (
        <div className="h-full w-full">
          {props?.faceAnalysisResult?.faceType &&
            props?.faceAnalysisResult?.faceTypeDesc &&
            renderFaceType(
              props?.faceAnalysisResult?.faceType,
              props?.faceAnalysisResult?.faceTypeDesc
            )}
          {props.faceTypeImage && (
            <div className="w-1/2 mt-4">
              <img
                src={props.faceTypeImage}
                alt="face_type_image"
                className="mx-auto"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="text-center text-sm text-gray-500">データなし</div>
      )}
    </>
  );
};

export default FaceTypeClinic;
