import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useCustomerMessageListQuery } from "api/useMessage";
import { ROUTE } from "constants/paths";
import dayjs from "dayjs";
import { groupBy, isEmpty, sortBy } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

import Container from "components/Container";
import Loading from "components/Loading";
import MessageList from "components/MessageList";

interface messageProps {
  customerId?: string;
  byAdmin?: boolean
  showMess?: boolean;
  branchId?: string;
}

const INIT_PARAMS = {
  page: 1,
  per: 5
};

const groupMessByDate = (mess: any) => {
  if (isEmpty(mess)) return [];
  let data = mess.map((item: any) => ({
    ...item,
    date: dayjs(item?.sent_date).format("YYYY.MM.DD"),
    time: dayjs(item?.sent_date).valueOf()
  }));
  data = sortBy(data, 'time')
  return groupBy(data, "date");
};

const Message = ({ customerId, byAdmin, branchId, showMess = false }: messageProps) => {
  const navigator = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [params, setParams] = useState(INIT_PARAMS);
  const [showDetail, setShowDetail] = useState(showMess);

  const onPageChange = (page: number) => {
    setParams({ ...params, page });
  };
  const { data: { messages = [], meta = {} } = {}, isLoading } =
    useCustomerMessageListQuery(params, customerId! || id!);

  return (
    <Container background="bg-[#F7F7F7] h-[calc(100vh-115px)] overflow-auto">
      {!showDetail && byAdmin && (
        <div className="mb-4 flex justify-center">
          <button
            className="bg-purple-400 text-white rounded-lg w-[264px] h-10"
            onClick={() => {
              sessionStorage.removeItem("MESSAGE_TARGET");
              navigator(`${ROUTE.MESSAGE.CREATE}?user_id=${customerId || id}`);
            }}
          >
            メッセージを送る
          </button>
        </div>
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!showDetail ? (
            <>
              <div className="border border-[#E0E0E0] rounded border-b-[0px] overflow-hidden">
                <Table>
                  <TableHead className="bg-[#F7F7F7]">
                    <TableRow>
                      {byAdmin && <TableCell className="w-1/2">シナリオ名</TableCell>}

                      <TableCell className="w-1/2">配信日</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="bg-white">
                    {messages?.map((mes: any) => (
                      <TableRow
                        key={mes.id}
                        onClick={() => {
                          setShowDetail(true);
                        }}
                        className="cursor-pointer"
                      >
                        {byAdmin && <TableCell className="w-1/2">
                          {mes?.title || "No title"}
                        </TableCell>}

                        <TableCell className="w-1/2">
                          {dayjs(mes.sent_date).format("YYYY/MM/DD HH:mm")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {!isEmpty(messages) && (
                <div className="mt-4 w-full flex items-center justify-center pb-5">
                  <Pagination
                    className="custom-pagination"
                    count={meta.total_pages || 1}
                    onChange={(_, page) => onPageChange(page)}
                    shape="rounded"
                    size="medium"
                    page={params.page}
                    variant="outlined"
                  />
                </div>
              )}
            </>
          ) : (
            <MessageList
              messages={groupMessByDate(messages)}
              customerId={customerId || id}
              byAdmin={byAdmin}
              branchId={branchId}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Message;
