import { calculateHeadTiltedPoint } from "../utils/face-analysis";

type Point = {
  x: number;
  y: number;
};

const drawLeftEyeBox = ({
  left_eye_left_corner,
  left_eye_right_corner,
  left_eye_top,
  left_eye_bottom
}: {
  left_eye_left_corner: Point;
  left_eye_right_corner: Point;
  left_eye_top: Point;
  left_eye_bottom: Point;
}) => {
  //const A = calculateHeadTiltedPoint(
  //left_eye_left_corner.x,
  //left_eye_left_corner.y,
  //left_eye_right_corner.x,
  //left_eye_right_corner.y,
  //left_eye_bottom.x,
  //left_eye_bottom.y
  //);
  //const B = calculateHeadTiltedPoint(
  //left_eye_left_corner.x,
  //left_eye_left_corner.y,
  //left_eye_right_corner.x,
  //left_eye_right_corner.y,
  //left_eye_top.x,
  //left_eye_top.y
  //);
  //const C = calculateHeadTiltedPoint(
  //left_eye_right_corner.x,
  //left_eye_right_corner.y,
  //left_eye_left_corner.x,
  //left_eye_left_corner.y,
  //left_eye_bottom.x,
  //left_eye_bottom.y
  //);
  //const D = calculateHeadTiltedPoint(
  //left_eye_right_corner.x,
  //left_eye_right_corner.y,
  //left_eye_left_corner.x,
  //left_eye_left_corner.y,
  //left_eye_top.x,
  //left_eye_top.y
  //);

  const A = {
    x: left_eye_right_corner.x,
    y: left_eye_bottom.y
  };
  const B = {
    x: left_eye_right_corner.x,
    y: left_eye_top.y
  };
  const C = {
    x: left_eye_left_corner.x,
    y: left_eye_bottom.y
  };

  const D = {
    x: left_eye_left_corner.x,
    y: left_eye_top.y
  };

  return { A, B, C, D };
};
const drawRightEyeBox = ({
  right_eye_left_corner,
  right_eye_right_corner,
  right_eye_top,
  right_eye_bottom
}: {
  right_eye_left_corner: Point;
  right_eye_right_corner: Point;
  right_eye_top: Point;
  right_eye_bottom: Point;
}) => {
  //const A = calculateHeadTiltedPoint(
  //right_eye_left_corner.x,
  //right_eye_left_corner.y,
  //right_eye_right_corner.x,
  //right_eye_right_corner.y,
  //right_eye_bottom.x,
  //right_eye_bottom.y
  //);
  //const B = calculateHeadTiltedPoint(
  //right_eye_left_corner.x,
  //right_eye_left_corner.y,
  //right_eye_right_corner.x,
  //right_eye_right_corner.y,
  //right_eye_top.x,
  //right_eye_top.y
  //);
  //const C = calculateHeadTiltedPoint(
  //right_eye_right_corner.x,
  //right_eye_right_corner.y,
  //right_eye_left_corner.x,
  //right_eye_left_corner.y,
  //right_eye_bottom.x,
  //right_eye_bottom.y
  //);
  //const D = calculateHeadTiltedPoint(
  //right_eye_right_corner.x,
  //right_eye_right_corner.y,
  //right_eye_left_corner.x,
  //right_eye_left_corner.y,
  //right_eye_top.x,
  //right_eye_top.y
  //);

  const A = {
    x: right_eye_right_corner.x,
    y: right_eye_bottom.y
  };
  const B = {
    x: right_eye_right_corner.x,
    y: right_eye_top.y
  };
  const C = {
    x: right_eye_left_corner.x,
    y: right_eye_bottom.y
  };
  const D = {
    x: right_eye_left_corner.x,
    y: right_eye_top.y
  };

  return { A, B, C, D };
};

export { drawLeftEyeBox, drawRightEyeBox };
