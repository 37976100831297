import React from "react";

import NoDataImg from "../../../../assets/images/no-data-img.svg";

interface NoImageProps {
  header?: string;
  styleImg?: string;
}

const NoImage: React.FC<NoImageProps> = (props) => {
  return (
    <div className="w-full">
      <p className="text-purple-500 text-sm">{props.header}</p>
      <div
        className={`relative w-full ${props.styleImg ? props.styleImg : ""}`}
      >
        <img
          src={NoDataImg}
          alt="noData"
          className="w-[144px] h-[166px] object-cover"
        />
      </div>
    </div>
  );
};

export default NoImage;
