import {
  calculateHeadTiltedPoint,
  tinhGiaoDiem,
  tinhPhuongTrinhDuongThang
} from "../utils/face-analysis";

export const calculateJunctionPoint = ({
  contour_left1,
  contour_right1,
  contour_chin,
  face_hairline_74,
  nose_tip,
  left_eye_bottom,
  right_eye_bottom
}: any) => {
  const middlePoint = {
    x: (left_eye_bottom.x + right_eye_bottom.x) / 2,
    y: (left_eye_bottom.y + right_eye_bottom.y) / 2
  };

  const face_hairline_74_D = calculateHeadTiltedPoint(
    left_eye_bottom.x,
    left_eye_bottom.y,
    right_eye_bottom.x,
    right_eye_bottom.y,
    face_hairline_74.x,
    face_hairline_74.y
  );

  const line_horizontal = tinhPhuongTrinhDuongThang(
    face_hairline_74.x,
    face_hairline_74.y,
    face_hairline_74_D.x,
    face_hairline_74_D.y
  );
  const line_vertical = tinhPhuongTrinhDuongThang(
    middlePoint.x,
    middlePoint.y,
    contour_chin.x,
    contour_chin.y
  );

  const junctionPoint = tinhGiaoDiem(
    line_horizontal.a,
    line_horizontal.b,
    line_vertical.a,
    line_vertical.b
  );

  const line_eye = tinhPhuongTrinhDuongThang(
    left_eye_bottom.x,
    left_eye_bottom.y,
    right_eye_bottom.x,
    right_eye_bottom.y
  );

  const contour_left1_D = calculateHeadTiltedPoint(
    contour_chin.x,
    contour_chin.y,
    nose_tip.x,
    nose_tip.y,
    contour_left1.x,
    contour_left1.y
  );

  const contour_right1_D = calculateHeadTiltedPoint(
    contour_chin.x,
    contour_chin.y,
    nose_tip.x,
    nose_tip.y,
    contour_right1.x,
    contour_right1.y
  );

  const line_countour_left = tinhPhuongTrinhDuongThang(
    contour_left1.x,
    contour_left1.y,
    contour_left1_D.x,
    contour_left1_D.y
  );

  const line_countour_right = tinhPhuongTrinhDuongThang(
    contour_right1.x,
    contour_right1.y,
    contour_right1_D.x,
    contour_right1_D.y
  );

  const leftPointResult = tinhGiaoDiem(
    line_eye.a,
    line_eye.b,
    line_countour_left.a,
    line_countour_left.b
  );
  const rightPointResult = tinhGiaoDiem(
    line_eye.a,
    line_eye.b,
    line_countour_right.a,
    line_countour_right.b
  );

  return {
    top: junctionPoint,
    bottom: contour_chin,
    left: leftPointResult,
    right: rightPointResult
  };
};
