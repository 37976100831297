import NoImage from "./NoImage";

interface IOtherImagesProps {
  faceAnalysisData: any;
}

const OtherImages: React.FC<IOtherImagesProps> = ({ faceAnalysisData }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <p className="text-purple-500 text-sm">Before</p>
        {faceAnalysisData?.other_images?.length > 0 ? (
          <div className="flex flex-col gap-2">
            {faceAnalysisData?.other_images?.map((item: any, index: number) => (
              <img
                key={index}
                src={item?.url}
                alt={item?.created_at}
                className="max-w-full max-h-full"
              />
            ))}
          </div>
        ) : (
          <NoImage />
        )}
      </div>

      <div>
        <p className="text-purple-500 text-sm">After</p>
        {faceAnalysisData?.after_other_images?.length > 0 ? (
          <div className="flex flex-col gap-2">
            {faceAnalysisData?.after_other_images?.map(
              (item: any, index: number) => (
                <img
                  key={index}
                  src={item?.url}
                  alt={item?.created_at}
                  className="max-w-full max-h-full"
                />
              )
            )}
          </div>
        ) : (
          <NoImage />
        )}
      </div>
    </div>
  );
};

export default OtherImages;
