import { useBranchByCodeQuery } from "api/useBranch";
import { useAddLineCustomer } from "api/useCustomer";
import { useAddReservation } from "api/useReservation";
import { LOCAL_STORAGE_KEY } from "constants/common";
import useLiff from "hooks/useLiff";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import InfoTab from "pages/Customer/components/InfoTab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Container from "components/Container";

import question_answer_icon from "../../../assets/icons/question-answer-icon.svg";
import customer_mypage_image from "../../../assets/images/customer-mypage-image.svg";
import ListComponent from "../components/ListComponent";

const Mypage: React.FC = () => {
  const { branchCode } = useParams();
  const [currentLiffId, setCurrentLiffId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [currentBranchId, setCurrentBranchId] = useState<string>("");
  const [currentBranchName, setCurrentBranchName] = useState<string>("");

  const branchQuery = useBranchByCodeQuery(branchCode || "");
  const lineProfile = useLiff(currentLiffId);
  const { mutate: addCustomer, data: customerData } = useAddLineCustomer();
  const { mutate: addReservation, data: reservationData } = useAddReservation();

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (
      branchCode &&
      branchQuery &&
      !branchQuery.isLoading &&
      !branchQuery.error &&
      branchQuery.data
    ) {
      setCurrentLiffId(branchQuery.data.branch.liff_id);
      setCurrentBranchId(branchQuery.data.branch.id);
      setCurrentBranchName(branchQuery.data.branch.name);
    }
  }, [branchCode, branchQuery]);

  useEffect(() => {
    if (lineProfile && currentBranchId) {
      const userCreateId =
        localStorage.getItem(LOCAL_STORAGE_KEY.USER_CREATE_ID) || null;
      const newCustomer = userCreateId
        ? {
            customer_id: userCreateId,
            first_name: "",
            first_name_kana: "",
            last_name: lineProfile.displayName || "",
            last_name_kana: "",
            gender: "",
            phone_number: "",
            zip_code: "",
            line_user_id: lineProfile.userId,
            branch_id: currentBranchId
          }
        : {
            first_name: "",
            first_name_kana: "",
            last_name: lineProfile.displayName || "",
            last_name_kana: "",
            gender: "",
            phone_number: "",
            zip_code: "",
            line_user_id: lineProfile.userId,
            branch_id: currentBranchId
          };
      addCustomer(newCustomer, {
        onSuccess: (response) => {
          // Customer新規登録時にReservationも新規登録する
          if (response.data.created === true) {
            const newReservationData = {
              customer_id: response.data.customer.id,
              branch_id: currentBranchId
            };
            addReservation(newReservationData);
          }
          localStorage.removeItem(LOCAL_STORAGE_KEY.USER_CREATE_ID);
        }
      });
    }
  }, [lineProfile, currentBranchId, addCustomer]);

  useEffect(() => {
    // APIレスポンスのデータから customer.id を取得してセット
    if (customerData && customerData.data && customerData.data.customer) {
      setCustomerId(customerData.data.customer.id.toString());
    }
  }, [customerData]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Container>
      <MenuHeader headerLabel={"マイページ"} />
      <div className="text-center border-t pt-8 mt-4 w-full">
        <p className="text-lg font-bold">問診 × フェイススキャン診断® で </p>
        <p>あなたに最適なメニューをご提案します</p>
      </div>
      <img
        src={customer_mypage_image}
        alt="customer_mypage_image"
        className="py-4 px-8 w-full h-auto"
      />
      <div className="flex items-center justify-center mb-4">
        <Link to={`/${branchCode}/customer-question`}>
          <button className="bg-purple-500 text-white px-4 py-2 rounded-full flex items-center">
            <img src={question_answer_icon} alt="Icon" className="mr-2" />
            今すぐ問診をはじめる
          </button>
        </Link>
      </div>
      <InfoTab
        onTabClick={handleTabClick}
        activeTab={activeTab}
        tab1="マイカルテ一覧"
      />
      {activeTab === 0 && (
        <ListComponent customerId={customerId} branchId={currentBranchId} />
      )}
    </Container>
  );
};

export default Mypage;
