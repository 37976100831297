import { useBranchQuery } from "api/useBranch";
import dayjs from "dayjs";

import Container from "components/Container";
import Loading from "components/Loading";

import placeIcon from "../../../../assets/icons/place.svg";

const getAddress = (url: string) => {
  if (!url) return ''
  const address = url.replace("https://www.google.co.jp/maps?q=", "");
  return address;
};
interface storeProps {
  branchId: number | string;
}
const StoreInformation = ({ branchId }: storeProps) => {
  const { data: { branch } = {}, isLoading } = useBranchQuery(branchId!);
  return (
    <Container background="bg-[#F7F7F7] min-h-[calc(100vh-115px)]">
      <button className="bg-purple-400 text-white rounded-lg w-full py-2 mt-2 mb-6">
        予約する
      </button>
      <div className="border-l-4 border-purple-500 pl-4 text-gray-700 mb-4 flex justify-between items-center ">
        <p>店舗情報</p>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="border border-[#EAEAEA] rounded bg-white p-4 text-[15px] leading-[21px]">
          <div className="mb-4">
            <div className="font-bold mb-2">営業時間</div>
            <div>{`${branch.start_time ? dayjs(branch.start_time.split(".")[0]).format("HH:mm") : ""}〜${branch.end_time ? dayjs(branch.end_time.split(".")[0]).format("HH:mm") : ""}`}</div>
          </div>
          <div className="mb-8">
            <div className="font-bold mb-2">定休日</div>
            <div>{branch.fixed_day_off}</div>
          </div>
          <div className="mb-8 flex justify-between items-start gap-4">
            <div>
              <div className="font-bold mb-2">住所</div>
              <div>{getAddress(branch.address)}</div>
            </div>
            {branch.address && (
              <a
                href={branch.address}
                target="_blank"
                className="border border-purple-500 rounded flex h-[24px] w-[71px] min-w-[71px] items-center justify-between px-2"
              >
                <img src={placeIcon} alt="place" />
                <span>MAP</span>
              </a>
            )}
          </div>
          <div className="mb-8">
            <div className="font-bold mb-2">電話番号</div>
            <div>{branch.phone_number}</div>
          </div>
          <div className="mb-8">
            <div className="font-bold mb-2">支払い方法</div>
            <div>{branch.payment_method}</div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default StoreInformation;
