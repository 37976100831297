interface ErrorProps {
  message: string;
  className?: string;
}

export default function Error(props: ErrorProps) {
  return (
    <div
      className={`text-red-500 text-xs ${!!props.className && props.className}`}
    >
      {props.message}
    </div>
  );
}
