import { CircularProgress } from "@mui/material";
// import { domToPng } from "modern-screenshot";
import { useRef, useState } from "react";

import EyeIcon1 from "../../../../assets/icons/ic-eye01.svg";
import EyeIcon2 from "../../../../assets/icons/ic-eye02.svg";
import EyeIcon3 from "../../../../assets/icons/ic-eye03.svg";
import NotAvailableImg from "../../../../assets/images/no-img-available.png";
import RatioSlider from "../../../../components/RatioSlider";
import {
  drawEyeAngle,
  drawEyeContour,
  drawEyeRatio
} from "../../../../services/draw-face-analysis.service";
import {
  calculateAngle,
  calculateTwoRatio,
  getDistanceOfTwoPoint,
  getLeftTriangleAngle,
  getRightTriangleAngle
} from "../../../../utils/face-analysis";
import HelpModal from "../FaceScanData/HelpModal";
import ScanLevel from "../ScanLevel";

interface IEyeClinicProps {
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImage: string;
  header: string;
  element: string;
  setIsLoadingEye: any;
  isLoadingEye: boolean;
  afterComponent?: React.ReactNode;
}

const EyeClinic = (props: IEyeClinicProps) => {
  // const isShowSection =
  //   props?.faceScanResult?.faceAnalysisResult?.checkedEyeFeature;

  const frontImg = props?.frontImage;

  const [isOpenHelp, setIsOpenHelp] = useState<boolean>(false);

  //--- Canvas for eye
  const canvasEyeRatio = useRef<HTMLCanvasElement>(null);
  const canvasEyeAngle = useRef<HTMLCanvasElement>(null);
  const canvasEyeContour = useRef<HTMLCanvasElement>(null);

  //--- Get data eye angle
  const [eyeLeftAngle, setEyeLeftAngle] = useState<number>(0);
  const [eyeRightAngle, setEyeRightAngle] = useState<number>(0);
  const [scanLevelEyeAngle, setScanLevelEyeAngle] = useState<number>(0);

  const getDataEyeAngle = () => {
    if (props.dataFaceLandmark) {
      // const angleLeft = getLeftTriangleAngle(
      //   props.dataFaceLandmark.left_eye_bottom,
      //   props.dataFaceLandmark.left_eye_left_corner
      // );

      const angleLeft = calculateAngle(
        props.dataFaceLandmark.left_eye_left_corner,
        props.dataFaceLandmark.left_eye_lower_left_quarter,
        props.dataFaceLandmark.left_eye_right_corner
      );

      // const angleRight = getRightTriangleAngle(
      //   props.dataFaceLandmark.right_eye_bottom,
      //   props.dataFaceLandmark.right_eye_right_corner
      // );
      const angleRight = calculateAngle(
        props.dataFaceLandmark.right_eye_right_corner,
        props.dataFaceLandmark.right_eye_lower_right_quarter,
        props.dataFaceLandmark.right_eye_left_corner
      );

      setEyeLeftAngle(Number(angleLeft.toFixed(0)));
      setEyeRightAngle(Number(angleRight.toFixed(0)));

      setScanLevelEyeAngle(
        calculateScanLevelEyeAngle(Number(angleRight.toFixed(0)))
      );
    }
  };

  //--- Calculate scan level
  const calculateScanLevelEyeAngle = (ratioResult: any) => {
    if (ratioResult < 0) return 1;
    if (ratioResult >= 0 && ratioResult < 5) return 2;
    if (ratioResult >= 5 && ratioResult < 10) return 3;
    if (ratioResult >= 10 && ratioResult < 15) return 4;
    return 5;
  };

  //--- Eye ratio
  const [eyeRatio1, setEyeRatio1] = useState<number>(0);
  const [eyeRatio2, setEyeRatio2] = useState<number>(0);

  const marksEyeRatio = [
    {
      value: 0
    },
    {
      value: eyeRatio1 > 0 && eyeRatio1
    },
    {
      value: 100
    }
  ];

  //--- Get data eye ratio
  const [scanLevelEyeRatio, setScanLevelEyeRatio] = useState<number>(0);

  const getDataEyeRatio = () => {
    if (props.dataFaceLandmark) {
      const distinct1 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.right_eye_top,
        props.dataFaceLandmark.right_eye_bottom
      );

      const distinct2 = getDistanceOfTwoPoint(
        props.dataFaceLandmark.right_eye_right_corner,
        props.dataFaceLandmark.right_eye_left_corner
      );

      const { ratio1, ratio2 } = calculateTwoRatio(distinct1, distinct2);

      setEyeRatio1(Number(ratio1));
      setEyeRatio2(Number(ratio2));

      const ratioResult = Number(Number(ratio1) / Number(ratio2));

      setScanLevelEyeRatio(calculateScanLevelEyeRatio(ratioResult));
    }
  };

  //--- Calculate scan level
  const calculateScanLevelEyeRatio = (ratioResult: any) => {
    if (ratioResult < 0.15) return 1;
    if (ratioResult >= 0.15 && ratioResult < 0.25) return 2;
    if (ratioResult >= 0.25 && ratioResult < 0.35) return 3;
    if (ratioResult >= 0.35 && ratioResult < 0.45) return 4;
    return 5;
  };

  //--- Generate image
  // const [eyeImgCrop, setEyeImgCrop] = useState<any>();

  // const generateImage = async (
  //   imgRealWidth: number,
  //   imgRenderWidth: number
  // ) => {
  //   props.setIsLoadingEye(true);

  //   const imageScale = imgRealWidth / imgRenderWidth;

  //   setTimeout(async () => {
  //     var eyeNode = document.getElementById(props.element);

  //     if (eyeNode === null) {
  //       return;
  //     }

  //     await domToPng(eyeNode).then(async (dataUrl: any) => {
  //       if (dataUrl) {
  //         if (eyeNode === null) {
  //           return;
  //         }

  //         await domToPng(eyeNode).then((dataUrl1: any) => {
  //           if (dataUrl1) {
  //             cropImage(
  //               dataUrl1,
  //               0,
  //               Math.floor(props.faceRectangle?.top / imageScale) - 8,
  //               imgRenderWidth,
  //               Math.floor(props.faceRectangle?.height / imageScale)
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_left1?.x / imageScale
  //               // ),
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.left_eyebrow_upper_left_quarter?.y /
  //               //     imageScale
  //               // ) - 10,
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_right1?.x / imageScale
  //               // ) -
  //               //   Math.floor(
  //               //     props.dataFaceLandmark?.contour_left1?.x / imageScale
  //               //   ),
  //               // Math.floor(
  //               //   props.dataFaceLandmark?.contour_right4?.y / imageScale
  //               // ) -
  //               //   Math.floor(
  //               //     props.dataFaceLandmark?.left_eyebrow_upper_left_quarter?.y /
  //               //       imageScale
  //               //   )
  //             );
  //           }
  //         });
  //       }
  //     });

  //     props.setIsLoadingEye(false);
  //   }, 4000);
  // };

  // const cropImage = (
  //   imageUrl: any,
  //   cropX: any,
  //   cropY: any,
  //   cropWidth: any,
  //   cropHeight: any
  // ) => {
  //   const canvas = document.createElement("canvas");

  //   if (canvas && imageUrl) {
  //     const image = new Image();

  //     image.onload = () => {
  //       canvas.width = cropWidth;
  //       canvas.height = cropHeight;

  //       const ctxCropImage = canvas.getContext("2d");

  //       if (ctxCropImage)
  //         ctxCropImage.drawImage(
  //           image,
  //           cropX,
  //           cropY,
  //           cropWidth,
  //           cropHeight,
  //           0,
  //           0,
  //           cropWidth,
  //           cropHeight
  //         );

  //       const dataUrl = canvas.toDataURL();

  //       setEyeImgCrop(dataUrl);
  //     };

  //     image.setAttribute("crossorigin", "anonymous");
  //     image.src = imageUrl;
  //   }
  // };

  //--- Handle load image
  const getDimensionsRealImg = (url: string, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const handleImageLoad = (e: any) => {
    props.setIsLoadingEye(true);

    const { width, height } = e.target;

    getDimensionsRealImg(frontImg, (err: any, img: any) => {
      let imgRealWidth = Number(img?.width);

      if (
        imgRealWidth > 0 &&
        width > 0 &&
        height > 0 &&
        props?.dataFaceLandmark &&
        props?.dataFaceDenseLandmark &&
        frontImg
      ) {
        Promise.all([
          //--- Draw eye contour
          drawEyeContour(
            props?.dataFaceDenseLandmark,
            canvasEyeContour,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye angle
          drawEyeAngle(
            props?.dataFaceLandmark,
            canvasEyeAngle,
            imgRealWidth,
            width,
            height
          ),
          //--- Draw eye ratio
          drawEyeRatio(
            props?.dataFaceLandmark,
            canvasEyeRatio,
            imgRealWidth,
            width,
            height
          ),
          //--- Get data eye ratio
          getDataEyeRatio(),
          //--- Get data eye angle
          getDataEyeAngle(),

          props.setIsLoadingEye(false)
          //--- Generate image
          // generateImage(imgRealWidth, width)
        ]);
      }
    });
  };

  return (
    <>
      <div
        className={`${props.header === "Before" ? "grid grid-cols-2 gap-4" : "w-full"}`}
      >
        <div className={`${props.header === "Before" ? "w-fit" : ""}`}>
          <p className="text-purple-500 text-sm">{props.header}</p>
          <div className="relative">
            {/* {eyeImgCrop ? (
            <div className="h-auto w-full">
              <img
                src={eyeImgCrop}
                alt="img crop"
                className="w-full h-[80px] object-contain"
              />
            </div>
          ) : (
            <> */}
            <div className="w-fit" id={props.element}>
              {frontImg && (
                <>
                  <img
                    src={frontImg}
                    alt="face-img"
                    className="max-w-full max-h-full"
                    onError={(e: any) => (e.target.src = NotAvailableImg)}
                    onLoad={handleImageLoad}
                  />

                  {/*--- Canvas eye ---*/}
                  <canvas
                    ref={canvasEyeContour}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeAngle}
                    className={`absolute top-0 left-0 right-0`}
                  />

                  <canvas
                    ref={canvasEyeRatio}
                    className={`absolute top-0 left-0 right-0`}
                  />
                </>
              )}
            </div>

            <div
              className={`after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black after:opacity-90 ${
                props.isLoadingEye ? "" : "hidden"
              }`}
            ></div>

            <div
              className={`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                props.isLoadingEye ? "" : "hidden"
              }`}
            >
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
            {/* </>
          )} */}

            {/* <div className="mt-12 w-full h-[1px] bg-[#262626]" /> */}
            {/* <div className="p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={EyeIcon1} alt="ratio icon" />
                <span className="ml-2">目輪郭</span>
              </div>
            </div>
          </div>
          <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}

            {/*--- Eye angle ---*/}
            {/* <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={EyeIcon2} alt="ratio icon" />
                  <span className="ml-2">角度</span>
                  <span className="ml-2">
                    {eyeRightAngle > 0 &&
                      eyeLeftAngle > 0 &&
                      `(${eyeRightAngle}° , ${eyeLeftAngle}°)`}
                  </span>
                </div>
              </div>
              <div className="text-sm flex gap-x-11">
                <span>{eyeRightAngle > 0 && `R: ${eyeRightAngle}°`}</span>
                <span className="ml-10">
                  {eyeLeftAngle > 0 && `L: ${eyeLeftAngle}°`}
                </span>
              </div>
            </div>
            <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}

            {/*--- Eye ratio ---*/}
            {/* <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={EyeIcon3} alt="ratio icon" />
                  <span className="ml-2">縦横比</span>
                </div>
              </div>

              {eyeRatio1 > 0 && eyeRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksEyeRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(eyeRatio1)}% / 2) - 1rem)`
                      }}
                    >
                      {eyeRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(eyeRatio1)}% + (${Math.ceil(
                          eyeRatio2
                        )}% / 2)) - 1rem)`
                      }}
                    >
                      {eyeRatio2}
                    </span>
                  </div>
                </div>
              )}
            </div> */}

            {/* <div className="mt-2 w-full h-[1px] bg-[#262626]" /> */}
          </div>
        </div>
        {props.afterComponent}
      </div>

      {props.header === "Before" && (
        <div className="mt-4">
          {/*--- Eye angle ---*/}
          <ScanLevel
            img={EyeIcon2}
            title="角度 (8°)"
            point={scanLevelEyeAngle}
            pointNames={["垂れ目", "平均", "つり目"]}
            otherPoint={
              <div className="flex justify-center gap-6 text-sm">
                <div>L: {eyeLeftAngle}°</div>
                <div>R: {eyeRightAngle}°</div>
              </div>
            }
            onSetOpenHelp={setIsOpenHelp}
          />

          {/*--- Eye ratio ---*/}
          <ScanLevel
            img={EyeIcon3}
            title="縦横比 (3:10)"
            point={scanLevelEyeRatio}
            pointNames={["横長", "平均", "丸い"]}
            measure={
              eyeRatio1 > 0 &&
              eyeRatio2 > 0 && (
                <div>
                  <div className="clinic-proposal-custom-ratio">
                    <RatioSlider marksArr={marksEyeRatio} />
                  </div>
                  <div className="flex relative text-xs text-black">
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(eyeRatio1)}% / 2) - 1rem)`
                      }}
                    >
                      縦: {eyeRatio1}
                    </span>
                    <span
                      className="absolute"
                      style={{
                        top: "-10px",
                        left: `calc((${Math.ceil(eyeRatio1)}% + (${Math.ceil(
                          eyeRatio2
                        )}% / 2)) - 1rem)`
                      }}
                    >
                      横: {eyeRatio2}
                    </span>
                  </div>
                </div>
              )
            }
            onSetOpenHelp={setIsOpenHelp}
          />

          {/*--- Eye contour ---*/}
          <ScanLevel
            showBefore={false}
            img={EyeIcon1}
            title="目輪郭"
            onSetOpenHelp={setIsOpenHelp}
          />
        </div>
      )}

      {isOpenHelp && (
        <HelpModal
          isOpen={isOpenHelp}
          closeModal={() => setIsOpenHelp(false)}
          title="目"
          content={
            <div>
              <p className="font-bold">【アドバイス】</p>
              現在の目の形をもとに、お客さまの理想に近づける髪型やメイク、施術を提案できます。
              <br />
              <br />
              主な目の形と特徴：
              <br /> 1.丸目
              <br />
              • 特徴: 縦幅が広く、目の輪郭が丸い。黒目の上下に白目部分が見える。
              <br />
              • 印象:
              かわいらしくキュートな印象で、実年齢より若く見られることが多い。
              <br />
              <br />
              2.アーモンドアイ
              <br />
              • 特徴:
              アーモンドのように横幅が広く、目頭と目尻がシャープ。黒目の上下に白目部分が見えない。
              <br />
              • 印象: 知的で大人っぽい美人系。日本人に多い目の形。
              <br />
              <br />
              3.切れ長
              <br />
              • 特徴: 横幅が広く、縦幅との比率が1:3。目尻が細長く伸びている。
              <br />
              • 印象: 知的でクールな印象を与える。
              <br />
              <br />
              4.つり目
              <br />
              • 特徴: 目尻が上向きで、シャープな輪郭。
              <br />
              • 印象: クールで意志の強そうな印象。
              <br />
              <br />
              5.たれ目
              <br />
              • 特徴: 目尻が下向きで、全体的に柔らかい輪郭。
              <br /> • 印象:
              優しく穏やかな雰囲気を持ち、「ほっとけない」印象を与える。
              <br />
              <br />
              6.半開き目
              <br />
              • 特徴: 上まぶたが重く、目が完全に開いていないように見える。
              <br />• 印象: ミステリアスで落ち着いた雰囲気。
            </div>
          }
        />
      )}
    </>
  );
};

export default EyeClinic;
