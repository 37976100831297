import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePaymentGetQuery } from "api/usePayment";
import { ROUTE } from "constants/paths";
import { useUser } from "context/UserContext";
import { INIT_ENV, STRIPE_PUBLISHABLE_KEY } from "environments";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { UserInfo } from "types/user";

import Loading from "components/Loading";

import PaymentAdd from "./PaymentAdd/Index";
import PaymentDetail from "./PaymentDetail/Index";

const stripePromise = loadStripe(
  INIT_ENV === "DEV" ? STRIPE_PUBLISHABLE_KEY.DEV : STRIPE_PUBLISHABLE_KEY.PROD
);

const Payment: React.FC = () => {
  const { userInfo } = useUser();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: paymentData, isLoading } = usePaymentGetQuery();
  const { data: isEditPayment } = useQuery(
    ["isEditPayment"],
    () => !!queryClient.getQueryData(["isEditPayment"]),
    {
      onError: (err) => {
        return false;
      },
      retry: false,
      initialData: false
    }
  );

  const hasPermission = (userInfo: UserInfo | null, roles: string[]) => {
    if (!userInfo) return false;
    return roles.includes(userInfo?.role);
  };

  useEffect(() => {
    if (!hasPermission(userInfo, ["system_admin", "admin", "owner"])) {
      navigate(ROUTE.MYPAGE.INDEX);
    }
  }, [navigate, userInfo]);

  return (
    <Elements stripe={stripePromise}>
      {isLoading ? (
        <Loading />
      ) : !paymentData || isEditPayment ? (
        <PaymentAdd />
      ) : (
        <PaymentDetail />
      )}
    </Elements>
  );
};

export default Payment;
