import { ROUTE } from "constants/paths";
import dayjs from "dayjs";
import { groupBy, isEmpty, sortBy } from "lodash";
import { useNavigate } from "react-router";
import { useCustomerMessageLineListQuery } from "api/useMessage";
import Loading from "components/Loading";
interface messProps {
  messages: any;
  customerId?: string;
  byAdmin?: boolean;
  branchId?: string
}


const checkIsAdmin = (mess?: any) => {
  return mess?.sender_type === 'channel'
};
const TODAY = dayjs().format("YYYY.MM.DD");

const INIT_PARAMS = {
  page: 1,
  per: 1000
};

const groupMessByDate = (mess: any[]): Record<string, any[]> => {
  if (isEmpty(mess)) return {};
  let data = mess.map((item: any) => ({
    ...item,
    date: dayjs(item?.created_at).format("YYYY.MM.DD"),
    time: dayjs(item?.created_at).valueOf(),
  }));
  data = sortBy(data, "time");
  return groupBy(data, "date");
};


const MessageList = ({ customerId, byAdmin, branchId }: messProps) => {
  const navigator = useNavigate();
  const { data: { messages = [] } = {}, isLoading } =
    useCustomerMessageLineListQuery({ ...INIT_PARAMS, customer_id: customerId, branch_id: branchId });

  if (isEmpty(messages) && !isLoading) return null;
  if (isLoading) return <Loading />

  const messagesByDate = groupMessByDate(messages)
  return (
    <div className="p-2.5">
      {Object.keys(messagesByDate)?.map((key: string) => (
        <>
          <div
            key={key}
            className="flex justify-center items-center mx-auto bg-[#333333]  rounded-xl text-white w-fit h-[27px] text-base px-2 mb-5"
          >
            <div>{key === TODAY ? "Today" : key}</div>
          </div>
          {(messagesByDate[key] ?? []).map((mess: any) => (
            <>{mess?.message_type !== 'sticker' && <div className="mb-5">
              {mess?.content && <div
                className={`flex ${checkIsAdmin(mess) ? "" : "justify-end"}`}
              >
                <div
                  className={`bg-white border border-[#EAEAEA] rounded p-2.5 w-fit whitespace-pre-wrap ${checkIsAdmin(mess) ? "" : "!bg-[#d4d4d4]"}`}
                >
                  {mess?.content}
                </div>
              </div>}

              {mess?.file_url && (
                <>
                  {mess.message_type === 'image' && <img
                    src={mess?.file_url}
                    className="w-full border border-[#EAEAEA] rounded mt-5"
                  />}
                  {mess.message_type === 'video' && <video controls>
                    <source src={mess?.file_url} type="video/mp4" />
                  </video>}
                </>
              )}
              <div
                className={`text-[12px] ${checkIsAdmin(mess) ? "" : "text-right"}`}
              >
                {dayjs(mess?.created_at).format("HH:mm")}
              </div>
            </div>}</>

          ))}
        </>
      ))}
      {byAdmin && (
        <div className="flex justify-center mt-4">
          <button
            className="bg-purple-400 text-white rounded-lg w-[264px] h-10"
            onClick={() => {
              sessionStorage.removeItem("MESSAGE_TARGET");
              navigator(`${ROUTE.MESSAGE.CREATE}?user_id=${customerId}`);
            }}
          >
            メッセージを送る
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageList;
