import { useEffect, useState } from "react";

import IconArrowDown from "../../assets/icons/ic-arrow-down.svg";
import { ISelectOption } from "../../types/common";
import Error from "../Error";

interface IFormSelectFieldProps {
  label?: string;
  subLabel?: string;
  isRequired?: boolean;
  placeholder?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  register?: any;
  errors?: any;
  labelClassName?: string;
  subLabelClassName?: string;
  options?: any[];
  watch?: any;
  placeholderText?: string;
  defaultSelectedValue?: any;
  containerClassName?: string;
  arrowDownIcon?: string;
  isShowNone?: boolean;
}

const RequiredLabel = () => <span className="text-[#FF0000]">*</span>;

export default function FormSelectFieldCustom({
  isShowNone = true,
  ...props
}: IFormSelectFieldProps) {
  const [expand, setExpand] = useState(false);

  return (
    <div className={`flex flex-col mt-8 ${props.containerClassName}`}>
      {!!props.label && (
        <div>
          <span className={`${props.labelClassName}`}>
            {props.label}{" "}
            {props?.subLabel && (
              <span className={`${props.subLabelClassName}`}>
                {props?.subLabel}
              </span>
            )}{" "}
            {props.isRequired && <RequiredLabel />}
          </span>
        </div>
      )}

      <div
        className={`relative flex flex-col ${props.label && "mt-[20px]"} ${
          props.inputContainerClassName
        }`}
        onClick={() => setExpand((pre) => !pre)}
        onBlur={() => setExpand(false)}
      >
        <select
          className={`peer/select hidden-arrow-select focus:outline-neutral-300 border border-solid border-[#E3E3E3] w-full h-full px-4 py-[11px] bg-white [&>option]:bg-white rounded-[4px] ${
            props.inputClassName
          }`}
          data-expand={expand}
          {...props.register}
          defaultValue={props.defaultSelectedValue}
        >
          <option value="" disabled hidden>
            {props.placeholderText}
          </option>

          {isShowNone && <option value="">{""}</option>}
          {props.options?.map((option: ISelectOption) => (
            <option key={option.value + option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {props.errors && (
          <div className="mt-1">
            <Error message={props.errors.message} />
          </div>
        )}
        <img
          src={props.arrowDownIcon ?? IconArrowDown}
          alt="ic-arrow-down"
          className="absolute top-6 right-4 -translate-y-1/2 duration-0 will-change-transform peer-data-[expand=true]/select:rotate-180 pointer-events-none"
        />
      </div>
    </div>
  );
}
