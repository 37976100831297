import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface IHelpModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  content: any;
}

const HelpModal: React.FC<IHelpModalProps> = ({
  isOpen,
  closeModal,
  title,
  content
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      scroll="paper"
      maxWidth={false}
      sx={{
        ".MuiPaper-root": {
          borderRadius: 0,
          maxWidth: "300px",
          width: "100%"
        },
        ".MuiDialogTitle-root": {
          padding: 0
        },
        ".MuiDialogContent-root": {
          padding: 0
        }
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="bg-[#333333] h-[50px] flex items-center text-white font-extrabold px-2">
          <ClearTwoToneIcon
            className="text-2xl cursor-pointer"
            onClick={closeModal}
          />
          <p className="font-bold text-base text-center w-full">{title}</p>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className="p-5 text-sm">{content}</div>
      </DialogContent>
    </Dialog>
  );
};

export default HelpModal;
