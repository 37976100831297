import {
  calculateHeadTiltedPoint,
  middlePoint,
  tinhGiaoDiem,
  tinhPhuongTrinhDuongThang
} from "../utils/face-analysis";

export const noseRatioService = ({
  nose_left,
  nose_right,
  contour_chin,
  nose_tip,
  left_eye_left_corner,
  right_eye_right_corner,
  mouth_upper_lip_left_contour2,
  mouth_upper_lip_right_contour2
}: any) => {
  // formula: y = ax + b
  // equation of the line from left_eye_left_corner to right_eye_right_corner
  const line_eye = tinhPhuongTrinhDuongThang(
    left_eye_left_corner.x,
    left_eye_left_corner.y,
    right_eye_right_corner.x,
    right_eye_right_corner.y
  );
  // equation of the line from mouth_left to mouth_right
  const line_mouth = tinhPhuongTrinhDuongThang(
    mouth_upper_lip_left_contour2.x,
    mouth_upper_lip_left_contour2.y,
    mouth_upper_lip_right_contour2.x,
    mouth_upper_lip_right_contour2.y
  );

  const DPointLeft = calculateHeadTiltedPoint(
    contour_chin.x,
    contour_chin.y,
    nose_tip.x,
    nose_tip.y,
    nose_left.x,
    nose_left.y
  );

  const DPointRight = calculateHeadTiltedPoint(
    contour_chin.x,
    contour_chin.y,
    nose_tip.x,
    nose_tip.y,
    nose_right.x,
    nose_right.y
  );

  // equation of the line from DPointLeft to nose_left
  const line_d_left = tinhPhuongTrinhDuongThang(
    DPointLeft.x,
    DPointLeft.y,
    nose_left.x,
    nose_left.y
  );
  // equation of the line from DPointRight to nose_right
  const line_d_right = tinhPhuongTrinhDuongThang(
    DPointRight.x,
    DPointRight.y,
    nose_right.x,
    nose_right.y
  );

  const topLeftPoint = tinhGiaoDiem(
    line_eye.a,
    line_eye.b,
    line_d_left.a,
    line_d_left.b
  );
  const bottomLeftPoint = tinhGiaoDiem(
    line_mouth.a,
    line_mouth.b,
    line_d_left.a,
    line_d_left.b
  );
  const topRightPoint = tinhGiaoDiem(
    line_eye.a,
    line_eye.b,
    line_d_right.a,
    line_d_right.b
  );
  const bottomRightPoint = tinhGiaoDiem(
    line_mouth.a,
    line_mouth.b,
    line_d_right.a,
    line_d_right.b
  );

  return {
    topLeftPoint: middlePoint(topLeftPoint, nose_left),
    bottomLeftPoint: middlePoint(bottomLeftPoint, nose_left),
    topRightPoint: middlePoint(topRightPoint, nose_right),
    bottomRightPoint: middlePoint(bottomRightPoint, nose_right)
  };
};
