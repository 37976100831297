import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useCustomerQuery } from "api/useCustomer";
import jsPDF from "jspdf";
import { domToPng } from "modern-screenshot";
import moment from "moment";
import React, { useRef, useState } from "react";

// import { convertImgUrlToBase64 } from "utils/face-analysis";
import AllFaceScan from "../../FaceScanBeforeAfter/AllFaceScan";
import EyeBrowClinic from "../../FaceScanBeforeAfter/EyeBrowClinic";
import EyeClinic from "../../FaceScanBeforeAfter/EyeClinic";
import LowerHalfFaceClinic from "../../FaceScanBeforeAfter/LowerHalfFaceClinic";
import MouthClinic from "../../FaceScanBeforeAfter/MouthClinic";
import NoImage from "../../FaceScanBeforeAfter/NoImage";
import NoseClinic from "../../FaceScanBeforeAfter/NoseClinic";
import SideFaceClinic from "../../FaceScanBeforeAfter/SideFaceClinic";
import FaceScanType from "../../FaceScanType";

interface ReportModalProps {
  isOpen: boolean;
  isReport: boolean;
  closeModal: () => void;
  title?: string;
  customer_id: string;
  echart_id: string;
  faceType: any;
  faceAnalysisData: any;
  dataFaceLandmark: any;
  dataFaceDenseLandmark: any;
  faceRectangle: any;
  frontImgUrl: any;
  dataFaceLandmarkAfter: any;
  dataFaceDenseLandmarkAfter: any;
  faceRectangleAfter: any;
  afterFrontImgUrl: any;
  frontSideImgUrl: any;
  afterSideImgUrl: any;
  reservationAllData?: any;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  isReport,
  closeModal,
  title,
  customer_id,
  echart_id,
  faceType,
  faceAnalysisData,
  dataFaceLandmark,
  dataFaceDenseLandmark,
  faceRectangle,
  frontImgUrl,
  dataFaceLandmarkAfter,
  dataFaceDenseLandmarkAfter,
  faceRectangleAfter,
  afterFrontImgUrl,
  frontSideImgUrl,
  afterSideImgUrl,
  reservationAllData
}) => {
  const { data: customerBasicData } = useCustomerQuery(customer_id);

  let basicData = customerBasicData?.customer;

  let gender = !basicData?.gender
    ? ""
    : basicData?.gender === "female"
      ? "女性"
      : "男性";

  //--- State
  const [isLoadingEye, setIsLoadingEye] = useState(false);
  const [isLoadingLowerHalf, setIsLoadingLowerHalf] = useState(false);
  const [isLoadingNose, setIsLoadingNose] = useState(false);
  const [isLoadingEyeBrow, setIsLoadingEyeBrow] = useState(false);
  const [isLoadingMouth, setIsLoadingMouth] = useState(false);

  const [isDisable, setDisable] = useState(false);
  const [isShowModal, setShowModal] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const exportAsPng = async (typePrint: string) => {
    setDisable(true);
    setShowModal(false);
    await document.fonts.ready;

    if (contentRef.current) {
      const originalStyles = contentRef?.current?.style?.cssText;

      contentRef.current.style.overflow = "visible";
      contentRef.current.style.height = "auto";
      contentRef.current.style.width = `${contentRef.current.scrollWidth + 10}px`;

      try {
        var faceScanNode = document.getElementById("toPDF");

        if (faceScanNode === null) {
          return;
        }

        await domToPng(faceScanNode, { scale: 2 }).then((dataUrl: any) => {
          if (dataUrl) {
            if (typePrint === "PNG") {
              const link = document.createElement("a");
              link.download = `全体レポート.png`;
              link.href = dataUrl;
              link.click();
            } else {
              const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px"
              });

              const img = new Image();
              img.src = dataUrl;

              img.onload = () => {
                const imgWidth = img.width;
                const imgHeight = img.height;

                pdf.internal.pageSize.width = imgWidth;
                pdf.internal.pageSize.height = imgHeight;

                pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
                pdf.save("全体レポート.pdf");
              };
            }
          }
        });
      } catch (error) {
        console.error("Export failed: ", error);
      } finally {
        if (contentRef.current) {
          contentRef.current.style.cssText = originalStyles;
          setDisable(false);
        }
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      scroll="paper"
      maxWidth={false}
      sx={{
        ".MuiPaper-root": {
          borderRadius: 0,
          width: "700px"
        },
        ".MuiDialogTitle-root": {
          padding: 0,
          width: "100%"
        },
        ".MuiDialogContent-root": {
          padding: 0,
          width: "100%",
          overflowX: "auto"
        }
      }}
    >
      {/* Dialog Title */}
      <DialogTitle id="scroll-dialog-title" className="p-0 w-full ">
        <div className="bg-[#333333] w-full h-[50px] flex items-center justify-between text-white font-extrabold px-4 relative">
          <ClearTwoToneIcon
            className="text-2xl cursor-pointer"
            onClick={closeModal}
          />
          <p className="font-bold text-base">{title || "全体レポート"}</p>
          {isDisable ? (
            <CircularProgress size="1rem" />
          ) : (
            <FileDownloadOutlinedIcon
              className={
                isDisable ||
                isLoadingEye ||
                isLoadingEyeBrow ||
                isLoadingLowerHalf ||
                isLoadingMouth ||
                isLoadingNose
                  ? "text-2xl text-[#757575]"
                  : "text-2xl"
              }
              onClick={() => {
                !isDisable &&
                  !isLoadingEye &&
                  !isLoadingEyeBrow &&
                  !isLoadingLowerHalf &&
                  !isLoadingMouth &&
                  !isLoadingNose &&
                  // exportAsPng();
                  setShowModal(!isShowModal);
              }}
            />
          )}
          {isShowModal && (
            <div className="!w-[auto] flex rounded-md absolute top-[35px] right-[10px] justify-center items-center z-10">
              <div
                onClick={() => {
                  exportAsPng("PNG");
                }}
                className="cursor-pointer w-[110px] h-[35px] bg-[#FFFFFF] flex flex-row justify-center text-sm items-center text-[#000] border-2 border-[#33B294]"
              >
                PNG
              </div>
              <div
                className="cursor-pointer w-[110px] h-[35px] bg-[#FFFFFF] flex flex-col justify-center text-sm items-center text-[#000] border-2 border-[#33B294]"
                onClick={() => {
                  exportAsPng("PDF");
                }}
              >
                PDF
              </div>
            </div>
          )}
        </div>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent className="p-0 w-full overflow-x-auto">
        <div
          className="flex flex-col bg-[#E9CCFF] p-4 gap-3 overflow-y-auto overflow-x-auto whitespace-nowrap"
          ref={contentRef}
          id="toPDF"
        >
          <p className="text-center text-[28px] font-bold w-full">
            AIステップ フェイススキャン診断®レポート
          </p>

          <div className="grid grid-cols-2 gap-4 min-w-[670px] md:min-w-full">
            <div className="flex flex-col gap-4">
              <div className="p-4 bg-white rounded-2xl flex flex-col gap-4">
                <div className="flex justify-start items-end gap-2">
                  <label className="flex font-medium text-base">
                    {`${basicData?.last_name}${basicData?.first_name}`} 様
                  </label>
                  <label className="flex font-normal text-sm text-[#757575]">
                    {`${gender ? `${gender}/` : ""}
                    ${basicData?.customer_age?.years ?? " "}歳
                    ${basicData?.customer_age?.months ?? " "}ヶ月`}
                  </label>
                </div>
                <div className="flex justify-start items-end gap-2">
                  <label className="flex font-normal text-sm text-[#333333]">
                    診断日時:{" "}
                    {moment(reservationAllData?.created_at).format(
                      "YYYY/MM/DD HH:mm"
                    )}
                  </label>
                </div>
              </div>

              {/* FaceScanType */}
              <div className="rounded-2xl bg-white p-4">
                <div className="text-2xl font-bold mb-2">フェイスタイプ</div>
                <FaceScanType
                  faceAnalysisData={faceAnalysisData}
                  faceType={faceType}
                />
              </div>

              {/* EyeClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">目</div>
                {faceAnalysisData &&
                  dataFaceLandmark &&
                  dataFaceDenseLandmark &&
                  frontImgUrl && (
                    <EyeClinic
                      dataFaceLandmark={dataFaceLandmark}
                      dataFaceDenseLandmark={dataFaceDenseLandmark}
                      faceRectangle={faceRectangle}
                      frontImage={frontImgUrl}
                      header={"Before"}
                      element={"eyeBeforeEl"}
                      setIsLoadingEye={setIsLoadingEye}
                      isLoadingEye={isLoadingEye}
                      afterComponent={
                        faceAnalysisData &&
                        dataFaceLandmarkAfter &&
                        dataFaceDenseLandmarkAfter &&
                        afterFrontImgUrl ? (
                          <EyeClinic
                            dataFaceLandmark={dataFaceLandmarkAfter}
                            dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                            faceRectangle={faceRectangleAfter}
                            frontImage={afterFrontImgUrl}
                            header={"After"}
                            element={"eyeAfterEl"}
                            setIsLoadingEye={setIsLoadingEye}
                            isLoadingEye={isLoadingEye}
                          />
                        ) : (
                          <NoImage header={"After"} />
                        )
                      }
                    />
                  )}
              </div>

              {/* LowerHalfFaceClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">顔下半分</div>
                {faceAnalysisData &&
                  dataFaceLandmark &&
                  dataFaceDenseLandmark &&
                  faceRectangle &&
                  frontImgUrl && (
                    <LowerHalfFaceClinic
                      dataFaceLandmark={dataFaceLandmark}
                      dataFaceDenseLandmark={dataFaceDenseLandmark}
                      faceRectangle={faceRectangle}
                      frontImage={frontImgUrl}
                      header={"Before"}
                      element={"lowerHalfFaceBeforeEl"}
                      setIsLoadingLowerHalf={setIsLoadingLowerHalf}
                      isLoadingLowerHalf={isLoadingLowerHalf}
                      afterComponent={
                        faceAnalysisData &&
                        dataFaceLandmarkAfter &&
                        dataFaceDenseLandmarkAfter &&
                        faceRectangleAfter &&
                        afterFrontImgUrl ? (
                          <LowerHalfFaceClinic
                            dataFaceLandmark={dataFaceLandmarkAfter}
                            dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                            faceRectangle={faceRectangleAfter}
                            frontImage={afterFrontImgUrl}
                            header={"After"}
                            element={"lowerHalfFaceAfterEl"}
                            setIsLoadingLowerHalf={setIsLoadingLowerHalf}
                            isLoadingLowerHalf={isLoadingLowerHalf}
                          />
                        ) : (
                          <NoImage header={"After"} />
                        )
                      }
                    />
                  )}
              </div>

              {/* NoseClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">鼻</div>
                {faceAnalysisData &&
                  dataFaceLandmark &&
                  dataFaceDenseLandmark &&
                  frontImgUrl && (
                    <NoseClinic
                      faceScanResult={faceAnalysisData?.front_image}
                      dataFaceLandmark={dataFaceLandmark}
                      dataFaceDenseLandmark={dataFaceDenseLandmark}
                      faceRectangle={faceRectangle}
                      frontImage={frontImgUrl}
                      header={"Before"}
                      element={"noseBeforeEl"}
                      setIsLoadingNose={setIsLoadingNose}
                      isLoadingNose={isLoadingNose}
                      afterComponent={
                        faceAnalysisData &&
                        dataFaceLandmarkAfter &&
                        dataFaceDenseLandmarkAfter &&
                        afterFrontImgUrl ? (
                          <NoseClinic
                            faceScanResult={faceAnalysisData?.after_front_image}
                            dataFaceLandmark={dataFaceLandmarkAfter}
                            dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                            faceRectangle={faceRectangleAfter}
                            frontImage={afterFrontImgUrl}
                            header={"After"}
                            element={"noseAfterEl"}
                            setIsLoadingNose={setIsLoadingNose}
                            isLoadingNose={isLoadingNose}
                          />
                        ) : (
                          <NoImage header={"After"} />
                        )
                      }
                    />
                  )}
              </div>

              {/* SideFaceClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">横顔</div>
                {faceAnalysisData && frontSideImgUrl ? (
                  <SideFaceClinic
                    faceAnalysisResult={
                      faceAnalysisData?.front_image?.faceAnalysisResult
                    }
                    sideImage={frontSideImgUrl}
                    header={"Before"}
                    customer_id={customer_id}
                    echart_id={echart_id}
                    isReport={isReport}
                    afterComponent={
                      faceAnalysisData && afterSideImgUrl ? (
                        <SideFaceClinic
                          faceAnalysisResult={
                            faceAnalysisData?.after_front_image
                              ?.faceAnalysisResult
                          }
                          sideImage={afterSideImgUrl}
                          header={"After"}
                          customer_id={customer_id}
                          echart_id={echart_id}
                          isReport={isReport}
                        />
                      ) : (
                        <NoImage header={"After"} />
                      )
                    }
                  />
                ) : (
                  <NoImage header={"Before"} />
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {/* AllFaceScan */}
              <AllFaceScan
                faceAnalysisData={faceAnalysisData}
                frontImgUrl={frontImgUrl}
                afterFrontImgUrl={afterFrontImgUrl}
              />

              {/* EyeBrowClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">眉</div>
                {faceAnalysisData &&
                  dataFaceLandmark &&
                  dataFaceDenseLandmark &&
                  frontImgUrl && (
                    <EyeBrowClinic
                      dataFaceLandmark={dataFaceLandmark}
                      dataFaceDenseLandmark={dataFaceDenseLandmark}
                      faceRectangle={faceRectangle}
                      frontImage={frontImgUrl}
                      header={"Before"}
                      element={"eyeBrowBeforeEl"}
                      setIsLoadingEyeBrow={setIsLoadingEyeBrow}
                      isLoadingEyeBrow={isLoadingEyeBrow}
                      afterComponent={
                        faceAnalysisData &&
                        dataFaceLandmarkAfter &&
                        dataFaceDenseLandmarkAfter &&
                        afterFrontImgUrl ? (
                          <EyeBrowClinic
                            dataFaceLandmark={dataFaceLandmarkAfter}
                            dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                            faceRectangle={faceRectangleAfter}
                            frontImage={afterFrontImgUrl}
                            header={"After"}
                            element={"eyeBrowAfterEl"}
                            setIsLoadingEyeBrow={setIsLoadingEyeBrow}
                            isLoadingEyeBrow={isLoadingEyeBrow}
                          />
                        ) : (
                          <NoImage header={"After"} />
                        )
                      }
                    />
                  )}
              </div>

              {/* MouthClinic */}
              <div className="rounded-2xl bg-white p-2">
                <div className="text-2xl font-bold mb-2">口</div>
                {faceAnalysisData &&
                  dataFaceLandmark &&
                  dataFaceDenseLandmark &&
                  frontImgUrl && (
                    <MouthClinic
                      dataFaceLandmark={dataFaceLandmark}
                      dataFaceDenseLandmark={dataFaceDenseLandmark}
                      faceRectangle={faceRectangle}
                      frontImage={frontImgUrl}
                      header={"Before"}
                      element={"mouthBeforeEl"}
                      setIsLoadingMouth={setIsLoadingMouth}
                      isLoadingMouth={isLoadingMouth}
                      afterComponent={
                        faceAnalysisData &&
                        dataFaceLandmarkAfter &&
                        dataFaceDenseLandmarkAfter &&
                        afterFrontImgUrl ? (
                          <MouthClinic
                            dataFaceLandmark={dataFaceLandmarkAfter}
                            dataFaceDenseLandmark={dataFaceDenseLandmarkAfter}
                            faceRectangle={faceRectangleAfter}
                            frontImage={afterFrontImgUrl}
                            header={"After"}
                            element={"mouthAfterEl"}
                            setIsLoadingMouth={setIsLoadingMouth}
                            isLoadingMouth={isLoadingMouth}
                          />
                        ) : (
                          <NoImage header={"After"} />
                        )
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReportModal;
